import React, { useState, useEffect } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Index from "../../Index";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import { ExperticeData } from "../../../../components/menu/ExperticeData";
import png from "../../../../assets/png";
import { switchList } from "./signUp.const";
import {
  StyledSwitch,
  StyledSwitchContainer,
  StyledTypeContainer,
} from "./signUp.style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const SignUp = () => {
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);
  const [zoneMaster, setZoneMaster] = useState([]);
  const [experticeData, setMachineData] = useState(ExperticeData);
  const [userType, setUserType] = useState("");
  const [signUp, setSignUp] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    zone: "",
    otherZone: "",
    referralCode: "",
    password: "",
  });

  const [selectedFirstChild, setSelectedFirstChild] = useState("");
  const [selectedSecondChild, setSelectedSecondChild] = useState("");
  const [selectedThirdChild, setSelectedThirdChild] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [showPassword, setShowPassword] = useState(true);

  const [showError, setShowError] = useState({
    firstNameError: "",
    mobileError: "",
    zoneError: "",
    otherZone: "",
    email: "",
    password: "",
    userType: "",
  });

  const [inputList, setInputList] = useState([
    {
      experience: "",
      expertise: [],
      id: Math.random(),
    },
  ]);

  const handleListAdd = () => {
    setInputList((pre) => {
      return [
        ...pre,
        {
          experience: "",
          expertise: [],
          id: Math.random(),
        },
      ];
    });
  };

  const onSwitch = (id) => {
    setUserType(id);
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const getZoneList = () => {
    DataService.get(Api.User.zoneMaster)
      .then((response) => {
        setZoneMaster(response.data.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    // Call Zone master list api
    setLoader(true);
    getZoneList();
  }, []);

  const handleRemoveItem = (index) => {
    let newList = [...inputList];
    let filterd = newList.filter((task, i) => task.id !== index);
    setInputList(filterd);
  };

  const tasksHandleChange = (e, index) => {
    const { value, name } = e.target;
    let arr = [...inputList];
    arr[index][name] = value;
    setInputList(arr);
  };

  const selectHandleChange = (value) => {
    let arr = [...inputList];
    arr[selectedId]["expertise"] = value;
    setInputList(arr);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setSignUp((data) => ({
      ...data,
      [name]: name === "mobileNumber" ? value.slice(0, 10) : value,
    }));
  };

  const register = async () => {
    /* API call for user registration */
    let formData = {
      firstName: signUp.fullName,
      email: signUp.email,
      referCode: signUp.referralCode,
      mobileNumber: signUp.mobileNumber,
      registerType: userType,
      password: signUp.password,
      zone: signUp.zone === "Other" ? signUp.otherZone : signUp.zone,
    };

    try {
      setLoader(true);
      const { data } = await DataService.post(
        Api.User.CompanyRegisterStep1,
        formData
      );
      setLoader(false);
      Index.toast.success(data?.message);
      setSignUp({
        fullName: "",
        email: "",
        mobileNumber: "",
        zone: "",
        otherZone: "",
        referralCode: "",
        password: "",
      });
      localStorage.setItem("userId", data?.data[0]?._id);
      navigate("verification-code");
    } catch (error) {
      setLoader(false);
      Index.toast.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async () => {
    let isFirstName = Index.nameCheck(
      signUp.fullName,
      "Please Enter Full Name",
      "Maximum 100 character are allowed, number,special character and white spacing not allowed."
    );
    let isEmail = Index.emailCheck(
      signUp.email,
      "Please Enter Email",
      "Please Enter Valid Email"
    );
    let isMobile = Index.numberCheck(
      signUp.mobileNumber,
      "Please Enter Mobile Number",
      "Please Enter Valid Mobile Number"
    );
    let isZone = Index.valueCheck(signUp.zone, "Please Select Zone");
    let isOtherZone;
    if (signUp.zone === "Other") {
      isOtherZone = Index.nameCheck(
        signUp.otherZone,
        "Please Enter Zone",
        "Maximum 100 character are allowed, number,special character and white spacing not allowed."
      );
    }

    let isPassword = Index.passwordCheck(
      signUp.password,
      "Please enter Password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    );

    let isRegisterType = Index.userTypeCheck(
      userType,
      "Please select Register Type"
    );

    setShowError((prev) => ({ ...prev, firstNameError: isFirstName }));
    setShowError((prev) => ({ ...prev, email: isEmail }));
    setShowError((prev) => ({ ...prev, mobileError: isMobile }));
    setShowError((prev) => ({ ...prev, zoneError: isZone }));
    setShowError((prev) => ({ ...prev, otherZone: isOtherZone }));
    setShowError((prev) => ({ ...prev, password: isPassword }));
    setShowError((prev) => ({ ...prev, userType: isRegisterType }));

    if (checked !== true) {
      setError("*Please Accept Terms & Conditions and Privacy Policy");
    } else {
      setError("");
    }

    if (isFirstName === "" && isMobile === "" && checked !== false) {
      let arr = inputList.map((data) => {
        let obj = { ...data };
        delete obj.other;
        delete obj.id;
        return {
          ...obj,
          expertise:
            data.expertise?.join("-") === "Others"
              ? data.expertise?.join("-") + "-" + data?.other
              : data.expertise?.join("-"),
        };
      });
      if (
        (isFirstName,
        !isEmail &&
          !isMobile &&
          !isZone &&
          !isOtherZone &&
          !isPassword &&
          !isRegisterType)
      ) {
        register();
      }
    }
  };

  const qualificationData = () => {
    DataService.get(Api.User.QualificationData).then((response) => {
      let temp = [];
      response.data.data.map((data, index) => {
        let res = {
          id: index + 1,
          name: data.qualification,
        };
        temp.push(res);
      });
      setData(temp);
    });
  };

  useEffect(() => {
    qualificationData();
  }, []);

  const [selectedOptions, setSelectedOptions] = useState([null, null, null]);

  // handle the selection of an option
  const handleOptionSelection = (index, optionValue) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = optionValue;
    setSelectedOptions(updatedOptions);
  };

  const buttonText = selectedOptions.reduce((text, option, index) => {
    if (option) {
      text += option;
    }
    if (index < selectedOptions.length - 1 && option) {
      text += " / ";
    }
    return text;
  }, "Expertise");

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setSelectedFirstChild("");
    setSelectedSecondChild("");
    setSelectedThirdChild("");
    setOpen(true);
  };
  const handleCloseMain = () => {
    setOpen(false);
  };
  const handleClose = (...value) => {
    selectHandleChange([...value]);
    setOpen(false);
    setSelectedId(null);
  };

  const defaultProps = {
    options: zoneMaster,
    getOptionLabel: (option) => option.zone,
  };

  return (
    <div>
      <Index.Box className="login-main">
        <Index.Logo />
        <Index.Box className="login-right-main">
          <Index.Box className="auth-inner-main">
            <Index.Box className="auth-inner">
              <Index.Box className="auth-logo-box">
                <img src={png.logo} alt="login-logo" className="login-logo" />
              </Index.Box>
              <Index.Box className="login-mobile-bg">
                <img src={png.loginbg} className="login-bg-img" />
                <Index.Box className="login-up-text-box">
                  <Index.Typography className="login-titles">
                    Looking for rewarding career
                  </Index.Typography>
                  <Index.Typography className="login-para">
                    We strive to achieve our dreams and goals to bring greater
                    effulgence in the future through the success and eminence of
                    bSetu startup solutions
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Typography variant="h5" className="auth-page-titles">
                Let's Join with Us
              </Index.Typography>
              <Index.Box className="login-bg">
                <Index.Box className="grid-row">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      className="grid-row"
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.BSTextField
                              inputProps={{
                                autoComplete: "off",
                                maxLength: 60,
                              }}
                              name="fullName"
                              id="outlined-basic"
                              label="Full Name"
                              variant="outlined"
                              placeholder="Enter Full Name"
                              error={showError.firstNameError}
                              onChange={(e) => onChange(e)}
                              value={signUp.firstName}
                            />
                            <Index.FormHelperText
                              sx={{ margin: "3px", color: "red" }}
                            >
                              {showError.firstNameError}
                            </Index.FormHelperText>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.BSTextField
                              inputProps={{ autoComplete: "off" }}
                              name="email"
                              id="outlined-basic"
                              label="Email"
                              variant="outlined"
                              placeholder="Enter Email"
                              error={showError.email}
                              onChange={(e) => onChange(e)}
                              value={signUp.email}
                            />
                            <Index.FormHelperText
                              sx={{ margin: "3px", color: "red" }}
                            >
                              {showError.email}
                            </Index.FormHelperText>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.BSTextField
                              inputProps={{
                                autoComplete: "off",
                                maxLength: 10,
                              }}
                              id="outlined-basic"
                              label="Mobile Number"
                              type="tel"
                              name="mobileNumber"
                              value={signUp.mobileNumber}
                              variant="outlined"
                              placeholder="Enter Mobile Number"
                              onChange={(e) => onChange(e)}
                              error={showError.mobileError}
                            />
                            <Index.FormHelperText
                              sx={{ margin: "3px", color: "red" }}
                            >
                              {showError.mobileError}
                            </Index.FormHelperText>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.Autocomplete
                              className="personal-profile-degree"
                              {...defaultProps}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  label="Select Zone"
                                  name="zone"
                                  value={signUp.zone}
                                  error={showError.zoneError}
                                  onSelect={(e) => onChange(e)}
                                />
                              )}
                            />
                            <Index.FormHelperText
                              sx={{ margin: "3px", color: "red" }}
                            >
                              {showError.zoneError}
                            </Index.FormHelperText>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      {signUp.zone === "Other" && (
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="auth-input-main">
                            <Index.FormControl
                              variant="outlined"
                              className="mail-input form-control"
                            >
                              <Index.BSTextField
                                inputProps={{ autoComplete: "off" }}
                                name="otherZone"
                                id="outlined-basic"
                                label="Other Zone"
                                variant="outlined"
                                placeholder="Enter Zone"
                                error={showError.otherZone}
                                onChange={(e) => onChange(e)}
                                value={signUp.otherZone}
                              />
                              <Index.FormHelperText
                                sx={{ margin: "3px", color: "red" }}
                              >
                                {showError.otherZone}
                              </Index.FormHelperText>
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      )}

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.BSTextField
                              inputProps={{
                                autoComplete: "off",
                                maxLength: 20,
                              }}
                              name="referralCode"
                              id="outlined-basic"
                              label="Referral Code(Optional)"
                              variant="outlined"
                              placeholder="Enter Referral Code"
                              onChange={(e) => onChange(e)}
                              value={signUp.referralCode}
                            />
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.BSTextField
                              inputProps={{
                                autoComplete: "off",
                                maxLength: 50,
                              }}
                              name="password"
                              id="outlined-basic"
                              label="Password"
                              variant="outlined"
                              placeholder="Enter Password"
                              error={showError.password}
                              onChange={(e) => onChange(e)}
                              value={signUp.password}
                              type={showPassword ? "text" : "password"}
                              secure
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton onClick={handlePassword}>
                                      {showPassword ? (
                                        <VisibilityOffOutlined fontSize="medium" />
                                      ) : (
                                        <VisibilityOutlined fontSize="medium" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Index.FormHelperText
                              sx={{ margin: "3px", color: "red" }}
                            >
                              {showError.password}
                            </Index.FormHelperText>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.FormControl
                            variant="outlined"
                            className="mail-input form-control"
                          >
                            <Index.Typography>Register as A</Index.Typography>
                            <StyledSwitchContainer>
                              {switchList.map((item) => (
                                <StyledTypeContainer
                                  onClick={() => onSwitch(item.id)}
                                >
                                  <Index.Checkbox
                                    checked={item.id === userType}
                                  />
                                  <StyledSwitch
                                    onClick={() => onSwitch(item.id)}
                                  >
                                    {item.title}
                                  </StyledSwitch>
                                </StyledTypeContainer>
                              ))}
                            </StyledSwitchContainer>
                            <Index.FormHelperText
                              sx={{ margin: "3px", color: "red" }}
                            >
                              {showError.userType}
                            </Index.FormHelperText>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>

                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-input-main">
                          <Index.Box>
                            <Index.Box sx={{ width: 1 }}>
                              {inputList?.length
                                ? inputList?.map((item, index) => {
                                    return (
                                      // add more fields
                                      <div key={0}>
                                        <Index.Box className="personal-inputs-main">
                                          <Index.Box className="mail-input form-control">
                                            <Index.Box className="personal-inputs-box">
                                              <Index.Box className="cust-grid-main">
                                                <Index.Box className="cust-grid-column-5 cust-grid-column">
                                                  <Index.Box className="auth-input-main auth-input-main2 auth-input-main3">
                                                    <Index.Box className="btn-expertise-list">
                                                      <Index.Button
                                                        className="expertise-btn-input"
                                                        onClick={() => {
                                                          setSelectedId(index);
                                                          handleOpen();
                                                        }}
                                                        disableRipple
                                                      >
                                                        {console.log(
                                                          inputList[index]
                                                            .expertise[0] ==
                                                            "Others",
                                                          734,
                                                          inputList[index]
                                                            ?.expertise[0]
                                                        )}
                                                        <span>
                                                          {inputList[index]
                                                            ?.expertise?.length
                                                            ? inputList[
                                                                index
                                                              ]?.expertise.join(
                                                                "/"
                                                              )
                                                            : "Expertise"}
                                                        </span>
                                                      </Index.Button>
                                                    </Index.Box>
                                                  </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="cust-grid-column-5 cust-grid-column">
                                                  <Index.Box className="auth-input-main">
                                                    <Index.TextField
                                                      id="standard-password-input"
                                                      label="Experience"
                                                      variant="outlined"
                                                      type="number"
                                                      placeholder="2 year"
                                                      className={`${
                                                        inputList[index]
                                                          .expertise[0] ===
                                                        "Others"
                                                          ? "field-signup-disabled"
                                                          : "text-width field-signup"
                                                      }`}
                                                      name="experience"
                                                      value={
                                                        inputList[index]
                                                          .experience
                                                      }
                                                      onChange={(e) =>
                                                        tasksHandleChange(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                    />
                                                  </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="cust-grid-column-1 cust-grid-column">
                                                  <Index.Box className="auth-input-main remove-center-btn">
                                                    {index >= 1 ? (
                                                      <RemoveCircleOutlineIcon
                                                        // className="remove-btn"
                                                        onClick={() =>
                                                          handleRemoveItem(
                                                            item.id
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        {inputList[index].expertise[0] ===
                                        "Others" ? (
                                          <>
                                            <Index.Box className="personal-ex">
                                              <Index.Box className="others-class-exp">
                                                <Index.Box>
                                                  <Index.TextField
                                                    id="standard-password-input"
                                                    label="Expertise"
                                                    name="other"
                                                    variant="outlined"
                                                    placeholder="XYZ"
                                                    className="expertise-btn-input"
                                                    size="small"
                                                    value={item?.other}
                                                    onChange={(e) =>
                                                      tasksHandleChange(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </Index.Box>
                                              </Index.Box>
                                              <Index.Box className="others-class-exp">
                                                <Index.Box>
                                                  <Index.TextField
                                                    id="standard-password-input"
                                                    label="Experience"
                                                    variant="outlined"
                                                    type="number"
                                                    placeholder="XYZ"
                                                    className="text-width field-signup"
                                                    size="small"
                                                    value={item?.experience}
                                                    onChange={(e) =>
                                                      tasksHandleChange(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                    name="experience"
                                                  />
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    );
                                  })
                                : " "}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="personal-pro-btn1">
                            <Index.BSButton
                              disabled={
                                inputList?.length &&
                                inputList?.some(
                                  (e) => e.expertise?.join() === "Others"
                                )
                              }
                              variant="Addmore"
                              className={`${
                                inputList?.length &&
                                inputList?.some(
                                  (e) => e.expertise?.join() === "Others"
                                )
                                  ? "add-more-btn add-more-btn-disabled"
                                  : "add-more-btn"
                              }`}
                              label="Add More"
                              onClick={handleListAdd}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-details">
                          <Index.Box className="">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  onChange={handleChange}
                                  checked={checked}
                                />
                              }
                              label={
                                <div>
                                  <Index.Box className="condition-main">
                                    <span className="condition-span">
                                      I Accept{" "}
                                    </span>
                                    <Link
                                      to="terms-and-conditions"
                                      className="link-decorations"
                                    >
                                      Terms & Conditions
                                    </Link>
                                    <span> & </span>
                                    <Link
                                      to="privacy-policy"
                                      className="link-decorations"
                                    >
                                      Privacy Policy
                                    </Link>
                                  </Index.Box>
                                </div>
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-details">
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {error}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="auth-details">
                          <Index.Box className="auth-submit-btn-main">
                            <Index.BSButton
                              variant="Submit"
                              className="submit-btn"
                              label="Submit"
                              onClick={(values) => handleSubmit(values)}
                            />
                            {loader && <CircularProgress />}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.ToastContainer />
      </Index.Box>

      {/* expertise modal start  */}
      <Index.Modal
        open={open}
        onClose={handleCloseMain}
        className="expertise-modal-main"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style} className="modal-inner-list">
          <Index.Box className="modal-body-pd">
            <Index.Box className="title-expertise-main">
              <Index.Typography
                component="h5"
                varient="h5"
                className="select-title-experties"
              >
                Select Experties
              </Index.Typography>
            </Index.Box>
            <Index.Box className="main-expertise-details">
              <Index.Box className="expertise-btn-listing">
                <Index.Box className="expertise-btn-list-inner">
                  {experticeData.map((data) => {
                    return (
                      <>
                        <Index.Box className="btn-list-main-exper">
                          <Index.Button
                            className={`expertise-btn-box ${
                              data.value === selectedFirstChild
                                ? `expertise-active-hide-show`
                                : ``
                            }`}
                            // onClick={() => handleOptionSelection( data.value)}
                            onClick={() => {
                              setSelectedFirstChild(data.value);
                              if (!data?.children) {
                                handleClose(data.value);
                              }
                            }}
                            // >
                          >
                            {data.label}
                          </Index.Button>
                        </Index.Box>
                      </>
                    );
                  })}
                </Index.Box>
              </Index.Box>
              <Index.Box className="expertise-btn-listing">
                {/* display-none-expertise --display none */}
                <Index.Box className="expertise-btn-list-inner">
                  {experticeData
                    .filter((e) => e.value === selectedFirstChild)
                    .map((data) => {
                      return (
                        <>
                          {data?.children?.map((element) => {
                            return (
                              <>
                                <Index.Box className="btn-list-main-exper">
                                  <Index.Button
                                    onClick={() => {
                                      setSelectedSecondChild(element.value);
                                      if (!element?.children) {
                                        handleClose(
                                          selectedFirstChild,
                                          element.value
                                        );
                                      }
                                    }}
                                    className={`expertise-btn-box ${
                                      element.value === selectedSecondChild
                                        ? `expertise-active-hide-show`
                                        : ``
                                    }`}
                                  >
                                    {element.label}
                                  </Index.Button>
                                </Index.Box>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </Index.Box>
              </Index.Box>
              <Index.Box className="expertise-btn-listing">
                {/* display-none-expertise --display none */}
                <Index.Box className="expertise-btn-list-inner">
                  {experticeData
                    ?.filter((e) => e.value === selectedFirstChild)[0]
                    ?.children?.filter(
                      (val) => val.value === selectedSecondChild
                    )[0]
                    ?.children?.map((last) => {
                      return (
                        <>
                          <Index.Box className="btn-list-main-exper">
                            <Index.Button
                              onClick={() => {
                                setSelectedThirdChild(last.value);
                                if (!last?.children) {
                                  handleClose(
                                    selectedFirstChild,
                                    selectedSecondChild,
                                    last.value
                                  );
                                }
                              }}
                              className={`expertise-btn-box ${
                                last.value === selectedThirdChild
                                  ? `expertise-active-hide-show`
                                  : ``
                              }`}
                            >
                              {last.value}
                            </Index.Button>
                          </Index.Box>
                        </>
                      );
                    })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </div>
  );
};

export default SignUp;
