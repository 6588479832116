import React from "react";
import { TextField } from "@mui/material";

const BSTextField = (props) => {
  return (
    <TextField
      id={props.id}
      label={props.label}
      variant={props.variant}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      error={props.error}
      inputProps={props.inputProps}
      name={props.name}
      type={props.type}
      className={props.className}
      InputProps={props.InputProps}
    />
  );
};
export default BSTextField;
