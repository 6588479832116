import React, { useState, useHistory } from "react";
import Index from "../../Index";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import { useLocation } from "react-router-dom";

const CompanyTypes = () => {
  const [company, setCompany] = useState();
  const { search } = useLocation();

  const onChange = (e) => {
    let { name, value } = e.target;
    setCompany((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handelSubmit = () => {
    let userId = localStorage.getItem("userid");
    let urlencoded = new URLSearchParams();
    let params = new URLSearchParams(search.substring(1));
    urlencoded.append("gstNumber", params.get("gst"));
    urlencoded.append("annualTurnOver", params.get("turnover"));
    urlencoded.append("noOfEmpolyees", params.get("employees"));
    urlencoded.append("companyType", company.companytype);
    urlencoded.append("companyAboutUs", company.aboutus);
    urlencoded.append("websiteLink", company.link);
    urlencoded.append("id", userId);

    DataService.post(Api.User.CompanyRegisterStep3, urlencoded)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
            <Index.Box className="auth-inner company-type-inner">
            <Index.Typography variant="h5" className="page-heading-main text-left">Company Type</Index.Typography>
                <Index.Box className="companytype-chechbox">
                  <Index.FormGroup>
                    <Index.FormControlLabel
                      control={<Index.Checkbox defaultUnChecked />}
                      label="Manufacture"
                      name="companytype"
                      value="Manufacture"
                      onChange={(e) => onChange(e)}
                    />
                    <Index.FormControlLabel
                      control={<Index.Checkbox defaultUnChecked />}
                      label="Supplier"
                      name="companytype"
                      value="Supplier"
                      onChange={(e) => onChange(e)}
                    />
                    <Index.FormControlLabel
                      control={<Index.Checkbox defaultUnChecked />}
                      label="Trader"
                      name="companytype"
                      value="Trader"
                      onChange={(e) => onChange(e)}
                    />
                  </Index.FormGroup>
                </Index.Box>

                <Index.Box className="comtype-textarea">
                  <Index.Typography className="auth-para">Write about your Company</Index.Typography>
                  <Index.TextareaAutosize
                    name="aboutus"
                    onChange={(e) => onChange(e)}
                    aria-label=""
                    minRows={3}
                    placeholder="Write about your company"
                  />
                </Index.Box>
              <Index.FormControl variant="standard" className="mail-input">
                <Index.BSTextField
                className="form-control"
                  name="link"
                  onChange={(e) => onChange(e)}
                  id="outlined-basic"
                  label="Add Website Link(Optional)"
                  variant="outlined"
                  placeholder="Add Website Link"
                  inputProps={{ autoComplete: "off" }}
                />
              </Index.FormControl>

              <Index.Box className="primary-btn-main">
                <Index.BSButton
                  onClick={() => handelSubmit()}
                  variant="Submit"
                  className="login-btn company-type-btn primary-btn"
                  label="Continue "
                />
              </Index.Box>
            </Index.Box>
            </Index.Box>
          </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};
export default CompanyTypes;
