import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Login from "../container/user/auth/login/Login";
import SignUp from "../container/user/auth/signUp/SignUp";
import Termsandconditions from "../container/user/pages/termCondition/Termsandconditions";
import Privacypolicy from "../container/user/pages/privacyPolicy/Privacypolicy";
import Header from "../components/defaultLayout/Header";
import Verificationcode from "../container/user/pages/verificationCode/Verificationcode";
import Emailverification from "../container/user/pages/emailverification/Emailverification";
import Createpassword from "../container/user/pages/createNewPassword/Createpassword";
import Profile from "../container/user/pages/profile/Profile";
import PersonalProfilesContinue from "../container/user/pages/personalProfile/PersonalProfilesContinue";
import CompanyProfiles from "../container/user/pages/companyProfile/CompanyProfiles";
import CompanyTypes from "../container/user/pages/companyProfile/CompanyTypes";
import PersonalProfiles from "../container/user/pages/personalProfile/PersonalProfiles";
import MiniDrawer from "../components/defaultLayout/Drawer";
import UpdateProfile from "../container/user/pages/profile/UpdateProfile";
import PersonHome from "../container/user/pages/home/PersonHome";
import ChangePassword from "../container/user/pages/changePassword/ChangePassword";
import ContactUs from "../container/user/pages/contactUs/ContactUs";
import Home from "../container/user/pages/home/Home";
import JobVacancy from "../container/user/pages/jobVacancy/JobVacancy";
import Product from "../container/user/pages/product/Product";
import Notification from "../container/user/pages/notification/Notification";
import JobWork from "../container/user/pages/jobWork/JobWork";
import CompanyDetail from "../container/user/pages/companyProfile/CompanyDetail";

// Admin Route
import AdminSignUp from "../container/admin/auth/SignUp";

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Routess>
          <Route path={"sign-in"} element={<Login />} />
          <Route exact path={"/"} element={<SignUp />} />
          <Route path={"admin-sign-up"} element={<AdminSignUp />} />
          <Route path={"/user"} element={<MiniDrawer />}>
            <Route path={"profile"} element={<Profile />} />
            <Route path={"update-profile"} element={<UpdateProfile />} />
            <Route path={"Person-home"} element={<PersonHome />} />
            <Route path={"change-password"} element={<ChangePassword />} />
            <Route path={"contact-us"} element={<ContactUs />} />
            <Route path={"home"} element={<Home />} />
            <Route path={"notification"} element={<Notification />} />
            <Route path={"job-vacancy"} element={<JobVacancy />} />
            <Route path={"job-work"} element={<JobWork />} />
            <Route path={"product"} element={<Product />} />
          </Route>

          <Route
            path={"terms-and-conditions"}
            element={<Termsandconditions />}
          />
          <Route path={"privacy-policy"} element={<Privacypolicy />} />
          <Route path={"email-verification"} element={<Emailverification />} />
          <Route path={"company-profiles"} element={<CompanyProfiles />} />
          <Route path={"company-types"} element={<CompanyTypes />} />
          <Route path={"company-detail"} element={<CompanyDetail />} />
          <Route path={"personal-profiles"} element={<PersonalProfiles />} />
          <Route
            path={"personal-profiles-continue"}
            element={<PersonalProfilesContinue />}
          />
          <Route path={"verification-code"} element={<Verificationcode />} />
          <Route path={"create-password"} element={<Createpassword />} />

          <Route path={"mini-drawer"} element={<Header />} />
        </Routess>
      </BrowserRouter>
    </>
  );
};
export default Routes;
