import React from "react";
import Index from "../../Index";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailIcon from "@mui/icons-material/Mail";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import png from "../../../../assets/png";

const ContactUs = () => {
  return (
    <div>
      <Index.Box className="main-login card-main">
        <Index.Box className="both-main profile-same">
          <Index.Box className="login-right profile-page">
            <Index.Box className="right-detail">
              <Index.Box className="cpass-heading">Contact Us</Index.Box>
              <Index.Box className="cpass-content">
                <Index.Box className="contact-no-detail">
                  <Index.Box className="contact-no-title">
                    <PhoneAndroidIcon />
                    <Index.Typography className="contact-text">
                      Phone Number
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="">
                    <Index.Box className="contact-numbers">
                      <Index.Typography>Ketan Patel</Index.Typography>
                      <Index.Typography className="pcolor">
                        +919724409332
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="contact-numbers">
                      {" "}
                      <Index.Typography>Prashant Patel</Index.Typography>
                      <Index.Typography className="pcolor">
                        +918905625103
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="contact-numbers">
                      <Index.Typography>Hardik Trivedi</Index.Typography>
                      <Index.Typography className="pcolor">
                        +919687613384
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="contact-email-details">
                  <Index.Box className="contact-email-title">
                    <MailIcon />
                    <Index.Typography className="contact-text">
                      Email Address
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="contact-email">
                    <Index.Typography>info@bsetu.com</Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="contact-email-details">
                  <Index.Box className="contact-field-title">
                    <ConnectWithoutContactIcon />
                    <Index.Typography className="contact-text">
                      Contact With Us
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="contact-field">
                    <Index.Box className="c-field">
                      <a href="https://www.instagram.com/bsetuofficial/" target="_blank">
                        <img src={png.instagram} />
                      </a>{" "}
                    </Index.Box>
                    <Index.Box className="c-field">
                      <a href="https://twitter.com/Bsetuofficial" target="_blank">
                        <img src={png.twitter} />
                      </a>
                    </Index.Box>
                    <Index.Box className="c-field">
                      <a href="https://www.facebook.com/Bsetuofficial/" target="_blank">
                        <img src={png.facebook} />
                      </a>
                    </Index.Box>
                    <Index.Box className="c-field">
                      <a href="https://www.linkedin.com/company/bsetu/" target="_blank">
                        <img src={png.linkedin} />
                      </a>
                    </Index.Box>
                    <Index.Box className="c-field">
                      <a href="https://www.youtube.com/channel/UCd00d0bEe5e0hfPc2L3WSiw" target="_blank">
                        <img src={png.youtube} />
                      </a>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ContactUs;
