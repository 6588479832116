export const ProcessData = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Casting",
    value: "Casting",
    children: [
      {
        label: "Aluminum",
        value: "Aluminum",
        children: [
          {
            label: "sand",
            value: "sand",
          },
          {
            label: "Die",
            value: "Die",
          },
        ],
      },
      {
        label: "Steel",
        value: "Steel",
        children: [
          {
            label: "sand",
            value: "sand",
          },
          {
            label: "Centrifugal",
            value: "Centrifugal",
          },
          {
            label: "Investment",
            value: "Investment",
          },
          {
            label: "Shell",
            value: "Shell",
          },
          {
            label: "Plaster mould",
            value: "Plaster mould",
          },
          {
            label: "Ceramic mould",
            value: "Ceramic mould",
          },
          {
            label: "Press Die",
            value: "Press Die",
          },
          {
            label: "Continuous",
            value: "Continuous",
          },
        ],
      },
    ],
  },

  {
    label: "Fabrication",
    value: "Fabrication",
  },

  {
    label: "Lapping",
    value: "Lapping",
  },
  {
    label: "Profile Projector",
    value: "Profile Projector",
  },
  {
    label: "CMM",
    value: "CMM",
  },
  {
    label: "Bending",
    value: "Bending",
    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
      {
        label: "Sheet",
        value: "Sheet",
      },
      {
        label: "Pipe",
        value: "Pipe",
      },
    ],
  },
  {
    label: "Hydraulic Press",
    value: "Hydraulic Press",
    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
    ],
  },

  {
    label: "Nitriding",
    value: "Nitriding",
    children: [
      {
        label: "Liquid",
        value: "Liquid",
      },
      {
        label: "Gas",
        value: "Gas",
      },
      {
        label: "Plasma",
        value: "Plasma",
      },
    ],
  },

  {
    label: "Welding",
    value: "Welding",
    children: [
      {
        label: "Argen",
        value: "Argen",
      },
      {
        label: "Gas",
        value: "Gas",
      },
      {
        label: "Arc",
        value: "Arc",
      },
      {
        label: "Spot",
        value: "Spot",
      },
      {
        label: "Mig",
        value: "Mig",
      },
      {
        label: "Plasma",
        value: "Plasma",
      },
    ],
  },

  {
    label: "Hardening",
    value: "Hardening",
    children: [
      {
        label: "Through Hardening",
        value: "Through Hardening",
      },
      {
        label: "Case Hardening",
        value: "Case Hardening",
      },
      {
        label: "Quenching",
        value: "Quenching",
      },
      {
        label: "Tempering",
        value: "Tempering",
      },
      {
        label: "Gas carburising",
        value: "Gas carburising",
      },
      {
        label: "Liquid Carburising",
        value: "Liquid Carburising",
      },
      {
        label: "Solid Carburising",
        value: "Solid Carburising",
      },
      {
        label: "Induction Hardening",
        value: "Induction Hardening",
      },
      {
        label: "Vacuum hardening",
        value: "Vacuum hardening",
      },
    ],
  },

  {
    label: "Annealing",
    value: "Annealing",
  },
  {
    label: "Stabizing",
    value: "Stabizing",
  },
  {
    label: "Others",
    value: "Others",
  },
];
