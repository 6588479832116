import React, { useState } from "react";
import Index from "../../Index";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

const Login = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signIn, setSignIn] = useState({
    email: "",
    password: "",
  });
  const [showError, setShowError] = useState({
    emailError: "",
    passError: "",
  });

  const onChange = (e) => {
    let { name, value } = e.target;
    setSignIn((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handelSubmit = () => {
    let isEmail = Index.emailCheck(
      signIn.email,
      "Please enter email",
      "Please enter valid email"
    );
    let isPass = Index.passwordCheck(
      signIn.password,
      "Please enter password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    );

    setShowError((prev) => ({ ...prev, emailError: isEmail }));
    setShowError((prev) => ({ ...prev, passError: isPass }));

    if (isEmail === "" && isPass === "") {
      let urlencoded = new URLSearchParams();
      urlencoded.append("email", signIn.email);
      urlencoded.append("password", signIn.password);
      urlencoded.append("registerType", page);

      DataService.post(Api.User.login, urlencoded)
        .then((response) => {
          if (response.data.status === 200) {
            localStorage.setItem("Token", response.data.data[0].token);
            // Index.toast.success(response.data.message);
            // setTimeout(() => {
            //   if(response.data.data[0].registerType === 0){
            //   navigate("/user/home");
            // }else if (response.data.data[0].registerType === 1){
            //   navigate("/user/person-home")
            // }
            if (page === 1) {
              navigate("/user/home");
            } else {
              navigate("/user/person-home");
            }
            // }, 2000);
          }
        })
        .catch((error) => {
          Index.toast.error(error.response.data.message);
        });
    }
  };

  return (
    <div>
      <Index.Box className="main-login">
        <Index.Box className="">
          <Index.Box className="both-main none-text-left">
            <Index.Logo />
            <Index.Box className="login-right">
              {/* <img src={png.logo} alt="login-logo" className="left-login-img-respo" /> */}
              <Index.Box className="right-detail">
                <Index.Typography variant="h5">Login</Index.Typography>
                <Index.FormControl variant="standard" className="mail-input">
                  <Index.BSTextField
                    className="email-input-place"
                    inputProps={{ autoComplete: "off" }}
                    id="outlined-basic"
                    label="Email"
                    name="email"
                    variant="outlined"
                    placeholder="Enter email address"
                    error={showError.emailError}
                    value={signIn.email}
                    onChange={(e) => onChange(e)}
                  />
                  <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.emailError}
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="pw-input"
                >
                  <Index.InputLabel
                    htmlFor="outlined-adornment-password"
                    inputProps={{ autoComplete: "off" }}
                  >
                    Password
                  </Index.InputLabel>
                  <Index.OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment position="end">
                        <Index.IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                    label="Password"
                    name="password"
                    placeholder="Enter Password"
                    error={showError.passError}
                    value={signIn.password}
                    onChange={(e) => onChange(e)}
                  />
                  <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.passError}
                  </Index.FormHelperText>
                </Index.FormControl>
                <Index.FormControl className="set-reg-box">
                  <Index.FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="register-as"
                  >
                    Login as
                  </Index.FormLabel>
                  <Index.RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="register-both-check register-as"
                  >
                    <Index.FormControlLabel
                      value="0"
                      className="register-check"
                      control={<Index.Radio />}
                      label="Company"
                      onClick={(e) => setPage(e.target.value)}
                    />
                    <Index.FormControlLabel
                      value="1"
                      className="register-check"
                      control={<Index.Radio />}
                      label="Person"
                      onClick={(e) => setPage(e.target.value)}
                    />
                  </Index.RadioGroup>
                </Index.FormControl>

                <Index.Box className="labels-login">
                  <Index.FormControlLabel
                    control={<Index.Checkbox defaultChecked />}
                    label="Remember Me"
                    className="Remember-text"
                  />
                  <Link to="/email-verification" className="login-forgot">
                    Forgot Password
                  </Link>
                </Index.Box>

                <Index.Box className="loginpage-button">
                  <Index.Box className="right-login-btn">
                    <Index.BSButton
                      variant="Submit"
                      className="login-btn"
                      label="Sign In"
                      onClick={() => handelSubmit()}
                    />
                  </Index.Box>

                  <Index.Box className="right-login-btn">
                    <Link to="/">
                      {" "}
                      <Index.BSButton
                        variant="Submit"
                        className="login-btn"
                        label="Register"
                      />
                    </Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.ToastContainer />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Login;
