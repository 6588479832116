import React, { useState } from "react";
import Index from "../../Index";
import { useNavigate } from "react-router-dom";

const Emailverification = () => {
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const handelSubmit = () => {
    setEmailError(
      Index.emailCheck(email, "Please enter email", "Please enter valid email")
    );
    if (email !== "" || emailError !== "") {
      navigate("/verification-code");
    }
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
            <Index.Box className="auth-inner email-verify-inner">
              <Index.Typography variant="h6" className="auth-para">
                Please enter your email to receive a verification code
              </Index.Typography>

              <Index.FormControl variant="standard" className="mail-input">
                <Index.BSTextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  placeholder="Enter email address"
                  className="email-input-place"
                  inputProps={{ autoComplete: "off" }}
                  error={emailError == "" ? false : true}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {emailError}
                </Index.FormHelperText>
              </Index.FormControl>

              <Index.Box className="primary-btn-main email-verfy-btn-main">
                <Index.BSButton
                  variant="Submit"
                  className="submit-btn primary-btn"
                  label="Confirm"
                  onClick={() => handelSubmit()}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};
export default Emailverification;
