import React, { useState } from "react";
import Index from "../../user/Index";
import { Link } from "react-router-dom";

const SignUp = () => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [signUp, setSignUp] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    country: "",
  });

  const [showError, setShowError] = useState({
    emailError: "",
    passError: "",
    firstNameError: "",
    lastNameError: "",
    mobileError: "",
    countryError: "",
  });
  
  const handelSubmit = () => {
    let isEmail = Index.emailCheck(
      signUp.email,
      "Please enter email",
      "Please enter valid email"
    );
    let isPass = Index.passwordCheck(
      signUp.password,
      "Please enter password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
    );
    let isFirstName = Index.valueCheck(
      signUp.firstName,
      "Please enter first name"
    );
    let isLastName = Index.valueCheck(
      signUp.lastName,
      "Please enter last name"
    );
    let isMobile = Index.numberCheck(
      signUp.mobileNumber,
      "Please enter mobile number",
      "Please enter a valid number"
    );
    let isCountry = Index.valueCheck(
        signUp.country,
        "Please enter country name"
      );

    setShowError((prev) => ({ ...prev, emailError: isEmail }));
    setShowError((prev) => ({ ...prev, passError: isPass }));
    setShowError((prev) => ({ ...prev, firstNameError: isFirstName }));
    setShowError((prev) => ({ ...prev, lastNameError: isLastName }));
    setShowError((prev) => ({ ...prev, mobileError: isMobile }));
    setShowError((prev) => ({ ...prev, countryError: isCountry }));
    if (checked !== true) {
      setError("Please accept Terms & conditions and privacy policy");
    } else {
      setError("");
    }
    if (
      isEmail === "" &&
      isPass === "" &&
      isFirstName === "" &&
      isLastName === "" &&
      isMobile === "" &&
      isCountry === "" &&
      checked !== false
    ) {
        alert('Form Submited');
        console.log('signUp =',signUp,90);
        //navigate("/verification-code?type=company");
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setSignUp((data) => ({
      ...data,
      [name]: value,
    }));
  };

  return (
    <>
     <Index.Box className="signup-admin-main">
      <Index.Box className="signup-admin">
        <Index.Box className="right-detail signup-detail">
          <Index.Typography variant="h5" className="page-heading">
            Sign-Up
          </Index.Typography>
          <Index.FormControl variant="standard" className="mail-input">
            <Index.BSTextField
              name="firstName"
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              placeholder="Enter first name"
              error={showError.firstNameError}
              onChange={(e) => onChange(e)}
              value={signUp.firstName}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {showError.firstNameError}
            </Index.FormHelperText>
          </Index.FormControl>

          <Index.FormControl variant="standard" className="mail-input">
            <Index.BSTextField
              id="outlined-basic"
              value={signUp.lastName}
              name="lastName"
              label="Last Name"
              variant="outlined"
              placeholder="Enter last name"
              onChange={(e) => onChange(e)}
              error={showError.lastNameError}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {showError.lastNameError}
            </Index.FormHelperText>
          </Index.FormControl>

          <Index.FormControl variant="standard" className="mail-input">
            <Index.BSTextField
              id="outlined-basic"
              label="Email"
              name="email"
              variant="outlined"
              placeholder="Enter email address"
              error={showError.emailError}
              value={signUp.email}
              onChange={(e) => onChange(e)}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {showError.emailError}
            </Index.FormHelperText>
          </Index.FormControl>

          <Index.FormControl variant="standard" className="mail-input">
            <Index.BSTextField
              id="outlined-basic"
              label="Mobile Number"
              type="number"
              name="mobileNumber"
              value={signUp.mobileNumber}
              variant="outlined"
              placeholder="Enter mobile number"
              onChange={(e) => onChange(e)}
              error={showError.mobileError}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {showError.mobileError}
            </Index.FormHelperText>
          </Index.FormControl>

          <Index.FormControl variant="standard" className="mail-input">
            <Index.BSTextField
              id="outlined-basic"
              value={signUp.country}
              name="country"
              label="Country"
              variant="outlined"
              placeholder="Enter country"
              onChange={(e) => onChange(e)}
              error={showError.countryError}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {showError.countryError}
            </Index.FormHelperText>
          </Index.FormControl>

          <Index.FormControl
            sx={{ m: 1, width: "25ch" }}
            variant="outlined"
            className="pw-input"
          >
            <Index.InputLabel
              htmlFor="outlined-adornment-password"
              inputProps={{ autoComplete: "off" }}
            >
              Password
            </Index.InputLabel>
            <Index.OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <Index.InputAdornment position="end">
                  <Index.IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {showPassword ? (
                      <Index.VisibilityOff />
                    ) : (
                      <Index.Visibility />
                    )}
                  </Index.IconButton>
                </Index.InputAdornment>
              }
              label="Password"
              name="password"
              placeholder="Enter Password"
              error={showError.passError}
              value={signUp.password}
              onChange={(e) => onChange(e)}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {showError.passError}
            </Index.FormHelperText>
          </Index.FormControl>

          <Index.Box>
            <Index.FormControlLabel
              className="register-as"
              control={
                <Index.Checkbox onChange={handleChange} checked={checked} />
              }
              label={
                <div>
                  <span>I Accept</span>{" "}
                  <Link to="terms-and-conditions" className="link-decorations">
                    Terms & Conditions
                  </Link>
                  <span> & </span>{" "}
                  <Link to="privacy-policy" className="link-decorations">
                    Privacy Policy
                  </Link>
                </div>
              }
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {error}
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="">
            <Index.BSButton
              variant="Submit"
              className="admin-submit-btn"
              label="Submit"
              onClick={() => handelSubmit()}
            />
          </Index.Box>

          {/* <Index.Box className="right-login-btn">
                <Index.Box className="text-account-line">
                  <span>Already have an account?</span>{" "}
                  <Link to="/sign-in" className="link-decorations">
                    Login
                  </Link>
                </Index.Box>
              </Index.Box> */}
        </Index.Box>
      </Index.Box>
      </Index.Box>
    </>
  );
};

export default SignUp;
