import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Logo from "../../components/logo/LoginLogo";
import BSButton from "../../components/button/BSButton";
import BSTextField from "../../components/inputField/BSTexField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
// import TextareaAutosize from '@mui/base/TextareaAutosize';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// or
import { TextareaAutosize } from "@mui/base";
import {
  emailCheck,
  passwordCheck,
  valueCheck,
  confirmPasswordCheck,
  numberCheck,
  otpCheck,
  nameCheck,
  maxCharacter,
  userTypeCheck,
} from "../../components/validation/AuthValidation";
import Countdown from "react-countdown";
import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ToastContainer, toast } from "react-toastify";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import "react-toastify/dist/ReactToastify.css";
import { ListItemAvatar } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";

let Index = {
  Modal,
  nameCheck,
  maxCharacter,
  SearchIcon,
  Select,
  ArrowForwardIosIcon,
  Accordion,
  numberCheck,
  AccordionSummary,
  AccordionDetails,
  ExpandMoreIcon,
  TextareaAutosize,
  confirmPasswordCheck,
  emailCheck,
  valueCheck,
  otpCheck,
  passwordCheck,
  FormGroup,
  Radio,
  FormHelperText,
  Countdown,
  FormLabel,
  RadioGroup,
  MenuItem,
  Menu,
  InboxIcon,
  MailIcon,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  ChevronRightIcon,
  ChevronLeftIcon,
  MenuIcon,
  Divider,
  List,
  Toolbar,
  CssBaseline,
  Drawer,
  Autocomplete,
  Stack,
  Logo,
  BSButton,
  BSTextField,
  Box,
  Typography,
  Link,
  Button,
  InputLabel,
  FormControl,
  TextField,
  Visibility,
  VisibilityOff,
  IconButton,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  ToastContainer,
  toast,
  ListItemAvatar,
  Avatar,
  userTypeCheck,
};

export default Index;
