import React, { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Index from "../../Index";
import { Password } from "@mui/icons-material";

const ChangePassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [passWord, setPassWord] = useState({
    Password: "",
    confirmPassword: "",
    currentPassword: "",
  });
  console.log(passWord.Password,"password",passWord.confirmPassword )

  const [showError, setShowError] = useState({
    passError: "",
    comfirmPasswordError: "",
    currentPasswordError: "",
  });
  const onChange = (e) => {
    let { name, value } = e.target;
    setPassWord((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handelSubmit = () => {
    let isCurrentPass = Index.passwordCheck(
      passWord.currentPassword,
      "Please enter current password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    );
    let isPass = Index.passwordCheck(
      passWord.Password,
      "Please enter new password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    );
    let isConfirmPass = Index.confirmPasswordCheck(
      passWord.Password,
      passWord.confirmPassword,
      "Please confirm new password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
      "Password does not match to new password"
    );
    setShowError((prev) => ({ ...prev, currentPasswordError: isCurrentPass }));
    setShowError((prev) => ({ ...prev, passError: isPass }));
    setShowError((prev) => ({ ...prev, comfirmPasswordError: isConfirmPass }));

  
    if (passWord.Password === passWord.confirmPassword) {
      let urlencoded = new URLSearchParams();
      urlencoded.append("oldPassword", passWord.currentPassword);
      urlencoded.append("newPassword", passWord.Password);

      DataService.post(Api.User.changePassword, urlencoded)
        .then((response) => {})
        .catch((error) => {
          Index.toast.success(error.response.data.message);
        });
    }
  };

  return (
    <div>
      <Index.Box className="main-login card-main">
        <Index.Box className="both-main profile-same none-text-left">
          <Index.Box className="login-right profile-page">
            <Index.Box className="right-detail">
              <Index.Box className="cpass-heading">Change Password</Index.Box>
              <Index.Box className="cpass-content">
                <Index.FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="mail-input"
                >
                  <Index.InputLabel
                    htmlFor="outlined-adornment-password"
                    inputProps={{ autoComplete: "off" }}
                  >
                    Current password
                  </Index.InputLabel>
                  <Index.OutlinedInput
                    id="outlined-adornment-password"
                    type={showCurrentPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment position="end">
                        <Index.IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowCurrentPassword((show) => !show)
                          }
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showCurrentPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                    label="Current password"
                    name="currentPassword"
                    placeholder="Current password"
                    error={showError.currentPasswordError}
                    value={passWord.currentPassword}
                    onChange={(e) => onChange(e)}
                  />
                  <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.currentPasswordError}
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="mail-input"
                >
                  <Index.InputLabel
                    htmlFor="outlined-adornment-password"
                    inputProps={{ autoComplete: "off" }}
                  >
                    New password
                  </Index.InputLabel>
                  <Index.OutlinedInput
                    id="outlined-adornment-password"
                    type={showNewPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment position="end">
                        <Index.IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                    label="New password"
                    name="Password"
                    placeholder="New password"
                    error={showError.passError}
                    value={passWord.Password}
                    onChange={(e) => onChange(e)}
                  />
                  <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.passError}
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  className="mail-input"
                >
                  <Index.InputLabel
                    htmlFor="outlined-adornment-password"
                    inputProps={{ autoComplete: "off" }}
                  >
                    Confirm new password
                  </Index.InputLabel>
                  <Index.OutlinedInput
                    id="outlined-adornment-password"
                    type={showNewConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment position="end">
                        <Index.IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowNewConfirmPassword((show) => !show)
                          }
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showNewConfirmPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                    label="Confirm new password"
                    name="confirmPassword"
                    placeholder="Confirm new password"
                    error={showError.comfirmPasswordError}
                    value={passWord.confirmPassword}
                    onChange={(e) => onChange(e)}
                  />
                  <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.comfirmPasswordError}
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.BSButton
                  variant="contained"
                  color="primary"
                  onClick={() => handelSubmit()}
                  className=" change-pw-btn"
                  label="Submit"
                ></Index.BSButton>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ChangePassword;
