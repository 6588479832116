import React from "react";
import png from "../../assets/png";
import { Box, Typography } from "@mui/material";
import Index from "../../container/user/Index";

const LoginLeft = () => {
  return (
    <>
        <Index.Box className="login-left-main">
          <Index.Box className="login-details-main">
            <Index.Box className="login-inner-main">
              <Index.Typography className="login-titles">
                Looking for rewarding career
              </Index.Typography>
              <Index.Typography className="login-para">
              With the bSetu web portal and mobile application, People will be able to fulfill their requirements on one platform. We strive to achieve our dreams and goals to bring greater effulgence in the future through the success and eminence of bSetu startup solutions, which started as a dream of one man.
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
    </>
  );
};

export default LoginLeft;
