import React from "react";
import Index from "../../container/user/Index";
import{ Link } from "react-router-dom";


const Settings = () => {
  return (
    <div>
     
      <Index.Accordion className="setting-main">
        <Index.AccordionSummary
          expandIcon={<Index.ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="setting-sub"
        >
          <Index.Typography className="setting-main-data">
            Settings
          </Index.Typography>
        </Index.AccordionSummary>
        <Index.AccordionDetails className="setting-dropdown">
          
          <Link to="/user/change-password" className="dropdown-link">
            <Index.Typography classname="setting-dropdown-item">
              Change Password{" "}
            </Index.Typography>
            <Index.ArrowForwardIosIcon className="profile-setting-icon" />
          </Link>
          <a href="https://www.bsetu.com/about-us/" target="_blank" className="dropdown-link" >
            <Index.Typography classname="setting-dropdown-item">
              About Us
            </Index.Typography>
            <Index.ArrowForwardIosIcon className="profile-setting-icon" />
          </a>
          <Link to="/user/contact-us" className="dropdown-link">
            <Index.Typography classname="setting-dropdown-item">
              Contact Us
            </Index.Typography>
            <Index.ArrowForwardIosIcon className="profile-setting-icon" />
          </Link>
        </Index.AccordionDetails>
      </Index.Accordion>
    </div>
  );
};

export default Settings;
