import logo from '../assets/images/bsetu.png';
import profile from "../assets/images/profile.png"
import instagram from "../assets/images/instagram.png"
import facebook from "../assets/images/facebook.png"
import twitter from "../assets/images/twitter.png"
import linkedin from "../assets/images/linkedin.png"
import youtube from "../assets/images/youtube.png"
import loginbg from "../assets/images/login-bg.jpg"



const png = {
    logo,
    profile,
    instagram,
    facebook,
    twitter,
    linkedin,
    youtube,
    loginbg

}

export default png