import React, { useState } from "react";
import Index from "../../Index";
import { useNavigate } from "react-router-dom";

const CompanyDetail = () => {
  const navigate = useNavigate();

  const [company, setCompany] = useState({
    gst: "",
    turnover: "",
    employees: "",
  });

  const [showError, setShowError] = useState({
    gstError: "",
    turnoverError: "",
    employeesError: "",
  });

  const onChange = (e) => {
    let { name, value } = e.target;
    setCompany((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handelSubmit = () => {
    let isGst = Index.valueCheck(company.gst, "Please enter gst");
    let isTurnover = Index.valueCheck(
      company.turnover,
      "Please enter turnover"
    );
    let isEmployees = Index.valueCheck(
      company.employees,
      "Please enter number of employees"
    );
    setShowError((prev) => ({ ...prev, gstError: isGst }));
    setShowError((prev) => ({ ...prev, turnoverError: isTurnover }));
    setShowError((prev) => ({ ...prev, employeesError: isEmployees }));

    if (
      company.gst !== "" &&
      company.turnover !== "" &&
      company.employees !== ""
    ) {
      navigate(
        `/company-types?gst=${company.gst}&turnover=${company.turnover}&employees=${company.employees}`
      );
    }
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
            <Index.Box className="auth-inner company-details-main">
              <Index.Typography variant="h5" className="page-heading-main text-left">
                Company Details
              </Index.Typography>

              <Index.FormControl variant="standard" className="mail-input">
                <Index.BSTextField
                  id="outlined-basic"
                  label="GST Number"
                  variant="outlined"
                  placeholder="Enter GST Number"
                  inputProps={{ autoComplete: "off" }}
                  name="gst"
                  error={showError.gstError}
                  onChange={(e) => onChange(e)}
                  value={company.gst}
                />

                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {showError.gstError}
                </Index.FormHelperText>
              </Index.FormControl>

              <Index.FormControl variant="standard" className="mail-input">
                <Index.BSTextField
                  id="outlined-basic"
                  label="Annual Turnover"
                  variant="outlined"
                  placeholder="Enter Annual Turnover"
                  inputProps={{ autoComplete: "off" }}
                  name="turnover"
                  error={showError.turnoverError}
                  onChange={(e) => onChange(e)}
                  value={company.turnover}
                />

                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {showError.turnoverError}
                </Index.FormHelperText>
              </Index.FormControl>

              <Index.FormControl variant="standard" className="mail-input">
                <Index.BSTextField
                  id="outlined-basic"
                  label="Number Of Employees"
                  variant="outlined"
                  placeholder="Enter Number Of Employees"
                  inputProps={{ autoComplete: "off" }}
                  name="employees"
                  error={showError.employeesError}
                  onChange={(e) => onChange(e)}
                  value={company.employees}
                />
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {showError.employeesError}
                </Index.FormHelperText>
              </Index.FormControl>

              <Index.Box className="primary-btn-main company-details-main-btn">
                <Index.BSButton
                  variant="Submit"
                  className="login-btn primary-btn"
                  label="Next "
                  onClick={() => handelSubmit()}
                ></Index.BSButton>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};
export default CompanyDetail;
