export const ExperticeData = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Helper",
    value: "Helper",
    children: [
      {
        label: "Cylindrical Lathe",
        value: "Cylindrical Lathe",
      },
      {
        label: "VTL",
        value: "VTL",
      },
      {
        label: "Drilling",
        value: "Drilling",
      },
      {
        label: "Shaping",
        value: "Shaping",
      },
      {
        label: "Milling",
        value: "Milling",
      },
      {
        label: "Boring",
        value: "Boring",
      },
      {
        label: "Plano miller",
        value: "Plano miller",
      },
      {
        label: "VMC",
        value: "VMC",
      },
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Honning",
        value: "Honning",
      },
    ],
  },
  {
    label: "Fitter",
    value: "Fitter",
  },
  {
    label: "Welder",
    value: "Welder",
    children: [
      {
        label: "Argen",
        value: "Argen",
      },
      {
        label: "Gas",
        value: "Gas",
      },
      {
        label: "Mig",
        value: "Mig",
      },
      {
        label: "Spot",
        value: "Spot",
      },
      {
        label: "Arc",
        value: "Arc",
      },
    ],
  },
  {
    label: "Operator",
    value: "Operator",
    children: [
      {
        label: "Cylindrical Lathe",
        value: "Cylindrical Lathe",
      },
      {
        label: "VTL",
        value: "VTL",
      },
      {
        label: "Surface grinding",
        value: "Surface grinding",
      },
      {
        label: "Centerless grinding",
        value: "Centerless grinding",
      },
      {
        label: "Cylindrical grinding",
        value: "Cylindrical grinding",
      },
      {
        label: "Polishing",
        value: "Polishing",
      },
      {
        label: "Wire cut",
        value: "Wire cut",
      },
      {
        label: "EDM/Spark",
        value: "EDM/Spark",
      },
      {
        label: "Radial Drilling",
        value: "Radial Drilling",
      },
      {
        label: "Tapping",
        value: "Tapping",
      },
      {
        label: "DRO milling",
        value: "DRO milling",
      },
      {
        label: "Conventional milling",
        value: "Conventional milling",
      },
      {
        label: "Table Boring",
        value: "Table Boring",
      },
      {
        label: "Floor Boring",
        value: "Floor Boring",
      },
      {
        label: "CNC Lathe",
        value: "CNC Lathe",
      },
      {
        label: "CNC VTL",
        value: "CNC VTL",
      },
      // {
      //   label: "VMC",
      //   value: "VMC",
      // },
      {
        label: "VMC",
        value: "VMC",
        children: [
          {
            label: "3 Axis",
            value: "3 Axis",
          },
          {
            label: "4 Axis",
            value: "4 Axis",
          },
          {
            label: "5 Axis",
            value: "5 Axis",
          },
        ],
      },
      {
        label: "Turn mill",
        value: "Turn mill",
      },
      {
        label: "Honning",
        value: "Honning",
      },
      {
        label: "Broaching",
        value: "Broaching",
      },
      {
        label: "Deep Hole",
        value: "Deep Hole",
      },
      {
        label: "Waterjet",
        value: "Waterjet",
      },
      {
        label: "Shapping",
        value: "Shapping",
      },
      {
        label: "Planing",
        value: "Planing",
      },
      {
        label: "Gear shaper",
        value: "Gear shaper",
      },
      {
        label: "Tool and Cutter",
        value: "Tool and Cutter",
      },
      {
        label: "Laser cutting",
        value: "Laser cutting",
      },
      {
        label: "Plano miller",
        value: "Plano miller",
      },
      {
        label: "Hobbing",
        value: "Hobbing",
      },
      {
        label: "Trob operator",
        value: "Trob operator",
      },
      {
        label: "Band saw",
        value: "Band saw",
      },
      {
        label: "Profile projector",
        value: "Profile projector",
      },
      {
        label: "Lapping",
        value: "Lapping",
      },
      {
        label: "Sheet Cutting",
        value: "Sheet Cutting",
      },
      {
        label: "Bending",
        value: "Bending",
        children: [
          {
            label: "CNC",
            value: "CNC",
          },
          {
            label: "Conventional",
            value: "Conventional",
          },
        ],
      },
      {
        label: "Hydraulic press",
        value: "Hydraulic press",
        children: [
          {
            label: "Manual",
            value: "Manual",
          },
          {
            label: "CNC",
            value: "CNC",
          },
        ],
      },
      {
        label: "Gas Cutting",
        value: "Gas Cutting",
      },
    ],
  },
  {
    label: "Programmers",
    value: "Programmers",
    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "VMC",
        value: "VMC",
        children: [
          {
            label: "2D",
            value: "2D",
          },
          {
            label: "3D",
            value: "3D",
          },
        ],
      },
      {
        label: "CNC Cutting",
        value: "CNC Cutting",
      },
      {
        label: "CNC Bending",
        value: "CNC Bending",
      },
    ],
  },

  {
    label: "Designer",
    value: "Designer",
    children: [
      {
        label: "AutoCad",
        value: "AutoCad",
      },
      {
        label: "Master Cam",
        value: "Master Cam",
      },
      {
        label: "Solid Edge",
        value: "Solid Edge",
      },
      {
        label: "Solid Works",
        value: "Solid Works",
      },
      {
        label: "ProvE",
        value: "ProvE",
      },
      {
        label: "Catia",
        value: "Catia",
      },
      {
        label: "Unigraphics",
        value: "Unigraphics",
      },
      {
        label: "Delcam",
        value: "Delcam",
      },
      {
        label: "Edgecam",
        value: "Edgecam",
      },
    ],
  },

  {
    label: "Engineer",
    value: "Engineer",
    children: [
      {
        label: "QC Engineer",
        value: "QC Engineer",
        children: [
          {
            label: "CMM",
            value: "CMM",
          },
          {
            label: "Trimos",
            value: "Trimos",
          },
          {
            label: "QC",
            value: "QC",
          },
        ],
      },
      {
        label: "Sales Engineer",
        value: "Sales Engineer",
        children: [
          {
            label: "Cutting Tools",
            value: "Cutting Tools",
          },
          {
            label: "Hand Tools",
            value: "Hand Tools",
          },
          {
            label: "Cutting Oil",
            value: "Cutting Oil",
          },
          {
            label: "Machine Tool",
            value: "Machine Tool",
          },
          {
            label: "Instrument",
            value: "Instrument",
          },
          {
            label: "Software",
            value: "Software",
          },
        ],
      },
      {
        label: "Sevice Engineer",
        value: "Sevice Engineer",
        children: [
          {
            label: "Machine",
            value: "Machine",
          },
          {
            label: "Instrument",
            value: "Instrument",
          },
          {
            label: "Crane/hoist",
            value: "Crane/hoist",
          },
        ],
      },
      {
        label: "Production Engineer",
        value: "Production Engineer",
        children: [
          {
            label: "Machine Shop",
            value: "Machine Shop",
          },
          {
            label: "Fabrication Shop",
            value: "Fabrication Shop",
          },
        ],
      },
      {
        label: "Purchase Engineer",
        value: "Purchase Engineer",
      },
    ],
  },
  {
    label: "Accountant",
    value: "Accountant",
  },
  {
    label: "Manager",
    value: "Manager",
  },
  {
    label: "Supervisor",
    value: "Supervisor",
  },
  {
    label: "Tele caller",
    value: "Tele caller",
  },

  {
    label: "Others",
    value: "Others",
  },
];
