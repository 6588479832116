export const switchList = [
  {
    id: 0,
    title: "Company",
  },
  {
    id: 1,
    title: "User",
  },
];
