import React, { useState, useEffect } from "react";
import Index from "../../container/user/Index";
import { useNavigate, Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Sidebar from "./SidebarContent";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddIcon from "@mui/icons-material/Add";
import png from "../../assets/png";
import { Api } from "../../config/Api";
import { DataService } from "../../config/DataService";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Settings from "./Settings";
import SettingsIcon from "@mui/icons-material/Settings";
import CompanyOptions from "./CompanyOptions";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
// in mui drawer dynamic option?
const MiniDrawer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  const [data, setData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  let companyOptions = [
    {
      name: "Company Home",
      logo: <AccountBoxIcon />,
      link: "/user/home",
    },

    {
      name: "Company Profile",
      logo: <AddIcon />,
      link: "/user/profile",
    },
  ];

  let personalOptions = [
    {
      name: "Person Home",
      logo: <AccountBoxIcon />,
      link: "/user/person-home",
    },
    {
      name: "Person Profile",
      logo: <PersonIcon />,
      link: "/user/profile",
    },
  ];

  useEffect(() => {
    // let urlencoded = new URLSearchParams();
    DataService.get(Api.User.profile)
      .then((response) => {
        setType(response.data.data[0].registerType);
        setData(response.data.data);
        // if (response.data.status === 200) {
        // setTimeout(() => {
        // navigate("/");
        // }, 2000);
        // }
      })
      .catch((error) => {});
  }, []);
  console.log(data, "jush12");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Index.Box
      sx={{ display: "flex", justifyContent: "space-between" }}
      className="main-dashboard"
    >
      <Index.CssBaseline />

      <AppBar position="fixed" open={open}>
        <Index.Toolbar className="main-headerbar">
          <Index.IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <Index.MenuIcon />
          </Index.IconButton>
          <Index.Box className="header-right-menu">
            <Link to="/user/notification">
              <Index.Button
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                className="btn-dropdown"
              >
                <NotificationsIcon />
              </Index.Button>
            </Link>
            <Index.Button
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className="btn-dropdown"
              onClick={handleClick}
            >
              <AccountCircleIcon />
            </Index.Button>
          </Index.Box>
          <Index.Menu
            className="main-user-select"
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link
              to="/user/update-profile"
              className="link-decorations logout-link"
            >
              <Index.MenuItem
                onClick={handleClose}
                className="head-menu logout-link"
              >
                <PersonIcon data={data} /> Profile
              </Index.MenuItem>
            </Link>
            <Link to="/sign-in" className="link-decorations logout-link">
              <Index.MenuItem onClick={handleClose} className="head-menu">
                <LogoutIcon /> Log out
              </Index.MenuItem>
            </Link>
          </Index.Menu>
        </Index.Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={png.logo} alt="login-logo" className="sidebar-logo" />
          <Index.IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <Index.ChevronRightIcon />
            ) : (
              <Index.ChevronLeftIcon />
            )}
          </Index.IconButton>
        </DrawerHeader>
        <Index.List className="sidebar-link">
          {(type === 0 ? companyOptions : personalOptions).map(
            (listData, index) => (
              <Index.ListItem
                key={listData}
                disablePadding
                sx={{ display: "block" }}
              >
                <Index.ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => navigate(listData.link)}
                >
                  <Index.ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {listData?.logo}
                  </Index.ListItemIcon>
                  <Index.ListItemText
                    primary={listData?.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Index.ListItemButton>
              </Index.ListItem>
            )
          )}
          {type === 0 ? (
            <Index.Box className="setings">
              <SupervisedUserCircleIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              />{" "}
              <CompanyOptions className="settings-div" />
            </Index.Box>
          ) : (
            " "
          )}
          <Index.Box className="setings">
            <SettingsIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            />{" "}
            <Settings className="settings-div" />
          </Index.Box>
        </Index.List>
      </Drawer>

      <Index.Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className="drawer-main"
      >
        <DrawerHeader />
        <Sidebar open={open} />
      </Index.Box>
    </Index.Box>
  );
};
export default MiniDrawer;
