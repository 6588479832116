import { React, useState, useEffect } from "react";
import Index from "../../Index";
import { DataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditProduct = ({ handleClose, editId, data , getProductList}) => {
 
  const [product, setProduct] = useState({
    productName: "",
    tags: "",
    description: "",
    files: "",
  });
  const [productError, setProductError] = useState({
    productNameError: "",
    tagsError: "",
    descriptionError: "",
    filesError: "",
  });

  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const fileHandler = (e) => {
    const File = e[0];
    console.log(File);
    let urllink = File.name;
    setUrl(urllink);
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setProduct((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handelSubmit = async () => {
    let isProductName = Index.valueCheck(
      product.productName,
      "Please enter productName"
    );
    let isTags = Index.valueCheck(product.tags, "Please enter tags");
    let isDescription = Index.valueCheck(
      product.description,
      "Please enter description"
    );
    setProductError((prev) => ({ ...prev, productNameError: isProductName }));
    setProductError((prev) => ({ ...prev, tagsError: isTags }));
    setProductError((prev) => ({ ...prev, descriptionError: isDescription }));

    const urlencoded = new FormData();
    let fileData = file && file[0] ? [...file] : [""];
    urlencoded.append("productId", editId);
    urlencoded.append("productName", product.productName);
    urlencoded.append("tags", product.tags);
    urlencoded.append("description", product.description);
    urlencoded.append("files", fileData[0]);

  
    await DataService.post(Api.User.ProductEdit, urlencoded)
      .then((response) => {
        console.log("Response : ", response.data);
        if (response.data.status === 201) {
          handleClose();
          Index.toast.success(response.data.message);
          getProductList();
        } else {
          Index.toast.error(response.data.message);
        }

      })
      .catch((error) => {
        Index.toast.error(error.response.data.message);
        console.log("HFGDGHSDFD", error);
      });
  };
  useEffect(() => {
    let names = {
      productName: data.productName,
      tags: data.tags,
      description: data.description,
    };
    setUrl(data.fileUrl);   
    setProduct(names);
  }, [data]);
  console.log(product, "Bharat56");
  console.log(data, "Bharat1256");

  return (
    <div>
      <Index.Box sx={style} className="change-pw-model">
        <h2>Edit Product</h2>
        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            productName
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="productName"
            name="productName"
            placeholder="productName"
            error={productError.productNameError}
            value={product.productName}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.productNameError}
          </Index.FormHelperText>
        </Index.FormControl>

        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            tags
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="tags"
            name="tags"
            placeholder="tags"
            error={productError.tagsError}
            value={product.tags}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.tagsError}
          </Index.FormHelperText>
        </Index.FormControl>
        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.Box className="inq-description">
            <Index.TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              name="description"
              placeholder="Description"
              className="inq-description-area"
              error={productError.descriptionError}
              value={product.description}
              onChange={(e) => onChange(e)}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {productError.descriptionError}
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box>
            <Index.Stack
              direction="row"
              alignItems="center"
              spacing={2}
              className="upload-bttn"
            >
              <Index.Button
                variant="contained"
                component="label"
                className="bttn-clr"
              >
                Upload File
                <input
                  accept="files/*"
                  multiple
                  type="file"
                  hidden
                  onChange={(e) => {
                    setFile(e.target.files);
                    fileHandler(e.target.files);
                  }}
                />
              </Index.Button>
              &nbsp;&nbsp;&nbsp; {url}
            </Index.Stack>
          </Index.Box>
        </Index.FormControl>

        <Index.Box>
          <Index.BSButton
            variant="contained"
            color="primary"
            onClick={() => handelSubmit()}
            className="submit-btn change-pw-btn"
            label="Submit"
          ></Index.BSButton>
        </Index.Box>
      </Index.Box>
    </div>
  );
};
export default EditProduct;
