import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import AddJobWork from "./AddJobWork";
import { Modal } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditJobWork from "./EditJobWork";
import Swal from "sweetalert2";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const JobWork = () => {
  const [jobData, setJobData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [Query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [data, setData] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const jobWorkApi = () => {
    let formdata = new FormData();
    DataService.get(Api.User.JobWorkList, formdata)
      .then((response) => {
        setJobData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    jobWorkApi();
    }, [open]);
    
  const handleSearch = (event) => {
    const getSearch = event.target.value;

    if (getSearch.length > 0) {
      const FilterData = jobData.filter(
        (item) =>
          item?.category?.toLowerCase().includes(getSearch?.toLowerCase()) ||
          item?.machinigJobDescription
            ?.toLowerCase()
            .includes(getSearch?.toLowerCase())
      );
      setSearchData(FilterData);
    } else {
      setSearchData(jobData);
    }
    setQuery(getSearch);
  };

  const onClickDelete = (jobWorkId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let formdata = new URLSearchParams();
        formdata.append("jobWorkId", jobWorkId);

        DataService.post(Api.User.JobWorkRemove, formdata)
          .then((response) => {
            console.log(response.data.data, "Kush546");
            Index.toast.success(response.data.message);
            setJobData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <div>
      <Index.Box className="main-login card-main portfolio-same">
        <Index.Box className="">
          <Index.Box className="heading">
            <Index.FormControl className="sub-heading">
              <p className="title"> Job Work </p>
            </Index.FormControl>
          </Index.Box>
          <Index.Box className="both-main profile-same">
            <Index.Box className="login-right profile-page">
              <Index.Box className="right-detail update-right">
                <Index.Box className="job-header">
                  <Index.Box className="job-search-select">
                    <Index.Box className="list">
                      <Search>
                        <SearchIconWrapper>
                          <Index.SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={Query}
                          className="search-input"
                          onChange={(e) => handleSearch(e)}
                        />
                      </Search>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="static-search">
                    <Index.Button onClick={(e) => handleOpen(e)}>
                      Add JobWork
                    </Index.Button>
                  </Index.Box>
                </Index.Box>

                {loader && <CircularProgress className="loader-icon" />}
                {(searchData.length > 0 ? searchData : jobData)?.map(
                  (row, index) => {
                    return (
                      <>
                        <Index.Box className="job-content">
                          <Index.Box className="job-image">
                            <img
                              src={`http://35.177.56.74:3012/files/${
                                row?.machiningFiles
                                  ? row?.machiningFiles
                                  : row.processesFiles
                              }`}
                              alt="job logo"
                              className=""
                            />
                          </Index.Box>
                          <Index.Box className="job-discription">
                            <Index.Typography>
                              {row.machiningTag ? row.machiningTag : row.processesTag}
                            </Index.Typography>
                            <Index.Typography>
                              {moment(row.createdAt).format("L")}
                            </Index.Typography>
                            <Index.Typography>
                              {row.machining ? row.machining : row.processes}
                            </Index.Typography>
                            <Index.Typography>
                              {row.machinigJobDescription ? row.machinigJobDescription : row.processesJobDescription}
                            </Index.Typography>
                            {/* <Inquiry id={row._id} /> */}
                          </Index.Box>
                          <Index.Box className="Update-icon-delete-icon">
                            <Index.Box className="delete-icon">
                              <EditIcon
                                onClick={() => {
                                  handleOpenEdit();
                                  setEditId(row?._id);
                                  setData(row);
                                }}
                              />
                            </Index.Box>
                            <Index.Box>
                              <DeleteIcon
                                onClick={() => {
                                  onClickDelete(row._id);
                                  setEditId(row._id);
                                }}
                              />
                            </Index.Box>
                          </Index.Box>
                          {/* <Index.Box className="job-save-icon">
                            <Index.Checkbox
                              className="check-box-bookmark"
                              icon={<BookmarkBorderIcon />}
                              checkedIcon={<BookmarkIcon />}
                            />
                          </Index.Box> */}
                        </Index.Box>
                      </>
                    );
                  }
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Modal
          title=""
          open={open}
          onCancel={handleClose}
          onClose={handleClose}
          className="job-work-modal"
        >
          <AddJobWork handleClose={handleClose} />
        </Modal>
        <Modal
          title=""
          open={openEdit}
          onCancel={handleCloseEdit}
          onClose={handleCloseEdit}
          className="job-work-modal"
        >
          <EditJobWork
            handleClose={handleCloseEdit}
            jobWorkApi={jobWorkApi}
            data={data}
            editId={editId}
          />
        </Modal>
        <Index.ToastContainer />
      </Index.Box>
    </div>
  );
};

export default JobWork;
