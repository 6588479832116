import React from "react";
import { CircularProgress } from "@mui/material";

const BSButton = (props) => {
  return (
    <button
      type={props.type}
      className={props.className}
      disabled={props.disabled ? props.disabled : false}
      onClick={props.onClick}
    >
      {props.loading ? (
        <CircularProgress
          size={20}
          sx={{
            color: "white",
            marginTop: 1,
          }}
        />
      ) : (
        props.label
      )}
    </button>
  );
};

export default BSButton;
