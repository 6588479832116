import React, { useState } from "react";
import Index from "../../Index";

const Createpassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showsPassword, setShowsPassword] = useState(false);
  const [passWord, setPassWord] = useState({
    Password: "",
    confirmPassword: "",
  });
  const [showError, setShowError] = useState({
    passError: "",
    comfirmPasswordError: "",
  });

  const onChange = (e) => {
    let { name, value } = e.target;
    setPassWord((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handelSubmit = () => {
    let isPass = Index.passwordCheck(
      passWord.Password,
      "Please enter password",
      "Minimum eight characters, at least one letter, one number and one special character"
    );
    let isConfirmPass = Index.confirmPasswordCheck(
      passWord.Password,
      passWord.confirmPassword,
      "Please enter password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
      "Password does not match"
    );
    setShowError((prev) => ({ ...prev, passError: isPass }));
    setShowError((prev) => ({ ...prev, comfirmPasswordError: isConfirmPass }));
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
            <Index.Box className="create-pass-inner auth-inner">
            <Index.Typography variant="h4" className="page-heading-main text-left">
              Create Password
            </Index.Typography>

              <Index.Box className="grid-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="outlined"
                          className="pw-input set-pw"
                        >
                          <Index.InputLabel
                            htmlFor="outlined-adornment-password"
                            inputProps={{ autoComplete: "off" }}
                          >
                            New password
                          </Index.InputLabel>
                          <Index.OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword((show) => !show)
                                  }
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                            label="New password"
                            name="Password"
                            placeholder="New password"
                            error={showError.passError}
                            value={passWord.Password}
                            onChange={(e) => onChange(e)}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.passError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="outlined"
                          className="pw-input"
                        >
                          <Index.InputLabel
                            htmlFor="outlined-adornment-password"
                            inputProps={{ autoComplete: "off" }}
                          >
                            Confirm new password
                          </Index.InputLabel>
                          <Index.OutlinedInput
                            id="outlined-adornment-password"
                            type={showsPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowsPassword((show) => !show)
                                  }
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showsPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                            label="Confirm new password"
                            name="confirmPassword"
                            placeholder="Confirm new password"
                            error={showError.comfirmPasswordError}
                            value={passWord.confirmPassword}
                            onChange={(e) => onChange(e)}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.comfirmPasswordError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="create-pass-btn-main">
                <Index.BSButton
                  variant="Submit"
                  className="submit-btn primary-btn"
                  label="Submit"
                  onClick={() => handelSubmit()}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Createpassword;
