import React, { useState } from "react";
import Index from "../../Index";
import { useNavigate } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import DegreeData from "../../../../components/DegreeData";

const PersonalProfiles = () => {
  const [inputList, setInputList] = useState([
    {
      experience: "",
      expertise: "",
      id: Math.random(),
    },
  ]);

  const [profile, setProfile] = useState({
    qualification: "",
    degree: "",
    company: "",
  });

  const [showError, setShowError] = useState({
    qualificationError: "",
    degreeError: "",
    expertiseError: "",
    experienceError: "",
    companyError: "",
  });

  //Methods
  const navigate = useNavigate();
  const defaultProps = {
    options: DegreeData,
    getOptionLabel: (option) => option.name,
  };

  const handleListAdd = () => {
    setInputList((pre) => {
      return [
        ...pre,
        {
          experience: "",
          expertise: "",
          id: Math.random(),
        },
      ];
    });
  };

  const handleRemoveItem = (index) => {
    let newList = [...inputList];
    let filterd = newList.filter((task, i) => task.id !== index);
    setInputList(filterd);
  };

  const tasksHandleChange = (e, index) => {
    const { value, name } = e.target;
    let arr = [...inputList];
    arr[index][name] = value;
    setInputList(arr);
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setProfile((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handelSubmit = () => {
    let userId = localStorage.getItem("userid");
    let isQualification = Index.valueCheck(
      profile.qualification,
      "Please enter qualification"
    );
    let isDegree = Index.valueCheck(profile.degree, "Please enter degree");
    let isExpertise = Index.valueCheck(
      inputList.expertise,
      "Please enter expertise"
    );
    let isExperience = Index.valueCheck(
      inputList.experience,
      "Please enter experience"
    );
    let isCompany = Index.valueCheck(profile.company, "Please enter company");

    setShowError((prev) => ({ ...prev, qualificationError: isQualification }));
    setShowError((prev) => ({ ...prev, degreeError: isDegree }));
    setShowError((prev) => ({ ...prev, expertiseError: isExpertise }));
    setShowError((prev) => ({ ...prev, experienceError: isExperience }));
    setShowError((prev) => ({ ...prev, companyError: isCompany }));

    if (
      profile.qualification !== "" &&
      profile.expertise !== "" &&
      profile.experience !== "" &&
      profile.company !== "" &&
      profile.degree !== ""
    ) {
      let urlencoded = new URLSearchParams();
      urlencoded.append("qualification", profile.qualification);
      urlencoded.append("qualificationData", JSON.stringify(inputList));
      urlencoded.append("degree", profile.degree);
      urlencoded.append("currentlyWorkingCompanyName", profile.company);
      urlencoded.append("id", userId);
      DataService.post(Api.User.PersonalProfile, urlencoded)
        .then((response) => {
          if (response.data.status == 201) {
            console.log("Response : ", response.data.data[0]._id);
            Index.toast.success(response.data.message);
            navigate("/sign-in");
          }
        })
        .catch((error) => {
          console.log(error);
          Index.toast.error(error.response.data.message);
        });
    }
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
          <Index.Box className="auth-inner-main">
            <Index.Box className="auth-inner personal-profile-main">
              <Index.Typography
                variant="h5"
                className="page-heading-main text-left"
              >
                Personal Profile
              </Index.Typography>

              <Index.Autocomplete
                className="personal-profile-degree"
                {...defaultProps}
                id="disable-clearable"
                disableClearable
                renderInput={(params) => (
                  <Index.TextField
                    {...params}
                    label="Qualification"
                    name="qualification"
                    variant="standard"
                    placeholder="Select"
                    error={showError.qualificationError}
                    onSelect={(e) => onChange(e)}
                    value={profile.qualification}
                  />
                )}
              />

              <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                {showError.qualificationError}
              </Index.FormHelperText>

              <Index.TextField
                label="Degree"
                name="degree"
                variant="standard"
                placeholder="Select"
                className="degree-width"
                onChange={(e) => onChange(e)}
                error={showError.degreeError}
                value={profile.degree}
              />

              <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                {showError.degreeError}
              </Index.FormHelperText>
              {inputList.length > 0
                ? inputList.map((item, index) => {
                    return (
                      // add more fields
                      <div key={0}>
                        <Index.Box className="">
                          <Index.Box className="cust-grid-main">
                            <Index.Box className="cust-grid-column-5 cust-grid-column">
                              <Index.Box className="auth-input-main">
                                {" "}
                                <Index.Box>
                                  <Index.TextField
                                    id="standard-password-input"
                                    label="Expertise"
                                    variant="standard"
                                    placeholder="XYZ"
                                    value={inputList[index].expertise}
                                    onChange={(e) =>
                                      tasksHandleChange(e, index)
                                    }
                                    name="expertise"
                                    error={showError.expertiseError}
                                  />
                                  <Index.FormHelperText
                                    sx={{ margin: "3px", color: "red" }}
                                  >
                                    {showError.expertiseError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="cust-grid-column-5 cust-grid-column">
                              <Index.Box className="auth-input-main">
                                {" "}
                                <Index.Box>
                                  <Index.TextField
                                    id="standard-password-input"
                                    label="Experience"
                                    variant="standard"
                                    placeholder="2 year"
                                    className="text-width"
                                    name="experience"
                                    value={inputList[index].experience}
                                    onChange={(e) =>
                                      tasksHandleChange(e, index)
                                    }
                                    error={showError.experienceError}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="cust-grid-column-1 cust-grid-column">
                              {index >= 1 ? (
                                <Index.Box className="auth-input-main">
                                  <RemoveCircleOutlineIcon
                                    className="delete-row"
                                    onClick={() => handleRemoveItem(item.id)}
                                  />
                                </Index.Box>
                              ) : (
                                ""
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </div>
                    );
                  })
                : " "}

              <Index.Box className="primary-btn-main personal-add-more-btn">
                <Index.BSButton
                  variant="Addmore"
                  className="add-more-btn primary-btn"
                  label="Add More"
                  onClick={handleListAdd}
                />
              </Index.Box>

              <Index.Box className="curr-work">
                <Index.Typography variant="p" className="current-work-text">
                  Current Working (Company Name)
                </Index.Typography>
                <Index.BSTextField
                  id="outlined-basic"
                  variant="outlined"
                  name="company"
                  onChange={(e) => onChange(e)}
                  error={showError.companyError}
                  value={profile.company}
                  placeholder="current company name"
                  inputProps={{ autoComplete: "off" }}
                />
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {showError.companyError}
                </Index.FormHelperText>
              </Index.Box>

              <Index.Box className="primary-btn-main personal-btn-main">
                <Index.BSButton
                  onClick={() => handelSubmit()}
                  variant="Submit"
                  className="login-btn primary-btn"
                  label="Complete "
                ></Index.BSButton>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        </Index.Box>
        <Index.ToastContainer />
      </Index.Box>
    </Index.Box>
  );
};
export default PersonalProfiles;
