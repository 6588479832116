import React, { useState, useEffect } from "react";
import Index from "../../Index";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import OTPInput from "otp-input-react";
import { StyledError } from "./verificationCode.style";

const Verificationcode = () => {
  const navigate = useNavigate();

  const checkNumeric = (value) => {
    const condition = new RegExp(/^[0-9 .]+$/);
    return condition.test(value);
  };

  const userId = localStorage.getItem("userId");

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [seconds, setSeconds] = useState(59);
  const [minutes, setMinutes] = useState(`00`.slice(-2));
  const [loading, setLoading] = useState(false);

  const timer = () => {
    let timeInterval = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(`0${seconds - 1}`.slice(-2));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timeInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  };

  useEffect(() => {
    timer();
  });

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const resendOTP = async () => {
    setOtp("");
    let formData = {
      id: userId,
    };
    try {
      const { data } = await DataService.post(Api.User.resendOtp, formData);
      Index.toast.success(data?.message);
      setSeconds(59);
      timer();
    } catch (error) {
      setTimeout(() => {
        Index.toast.success(error?.response?.data?.message);
      }, 300);
    }
  };

  const verifyOtp = async () => {
    /* API call for verifying OTP */
    try {
      let formData = {
        id: userId,
        otp: otp,
      };
      setLoading(true);
      await DataService.post(Api.User.verifyOtp, formData);
      setLoading(false);
      setOtp("");
      Index.toast.success(
        "You are Registered and our team will contact you soon"
      );
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      setLoading(false);
      Index.toast.error(error?.response?.data?.message);
    }
  };

  const handelSubmit = async () => {
    let isValid = true;
    if (!otp?.trim()) {
      isValid = false;
      setError("Please enter OTP");
    } else if (!checkNumeric(otp) || (otp?.length < 4 && otp?.length !== 4)) {
      isValid = false;
      setError("Please enter valid OTP");
    } else {
      setError("");
    }
    if (isValid) {
      verifyOtp();
    }
  };

  return (
    <Index.Box>
      <Index.Box className="login-main otp-screen">
        <Index.Logo />
        <Index.Box className="login-right-main">
          <Index.Box className="otp-inner-main auth-inner">
            <Index.Typography variant="h4" className="page-heading-main">
              Verify Otp
            </Index.Typography>
            <Index.Typography variant="h6" className="auth-para otp-para">
              We texted you a code to verify your registered mobile number
            </Index.Typography>
            <OTPInput
              length={4}
              otpType="number"
              value={otp}
              onChange={handleChange}
              className="otp-input-box"
            />
            {error && <StyledError>{error}</StyledError>}
            {seconds !== "00" && (
              <div className="min-timer">
                {minutes}:{seconds}{" "}
              </div>
            )}

            <Index.Box className="right-login-btn">
              <Index.Box className="text-account-line">
                <span>Didn't receive code?</span>{" "}
                <Link
                  style={{
                    pointerEvents: seconds === "00" ? undefined : "none",
                  }}
                  className="link-decorations"
                  onClick={resendOTP}
                >
                  Resend Otp
                </Link>
              </Index.Box>
            </Index.Box>

            <Index.Box className="right-login-btn">
              <Index.BSButton
                variant="Submit"
                className="submit-btn"
                label="Continue"
                onClick={() => handelSubmit()}
                loading={loading}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.ToastContainer />
    </Index.Box>
  );
};
export default Verificationcode;
