import React from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Profile from "../../container/user/pages/profile/Profile";
import UpdateProfile from "../../container/user/pages/profile/UpdateProfile";
import ChangePassword from "../../container/user/pages/changePassword/ChangePassword";
import ContactUs from "../../container/user/pages/contactUs/ContactUs";
import PersonHome from "../../container/user/pages/home/PersonHome";
import Home from "../../container/user/pages/home/Home";
import Notification from "../../container/user/pages/notification/Notification";
import JobVacancy from "../../container/user/pages/jobVacancy/JobVacancy";
import Product from "../../container/user/pages/product/Product";
import JobWork from "../../container/user/pages/jobWork/JobWork";

const drawerWidth = 200;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);


const Sidebar = (props) => {
  const location = useLocation();

  return (
    <Main open={props.open} className="panel-right">
      {location.pathname == "/user/profile" ? <Profile /> : ""}
      {location.pathname == "/user/update-profile" ? <UpdateProfile /> : ""}
      {location.pathname == "/user/person-home" ? <PersonHome /> : ""}
      {location.pathname == "/user/home" ? <Home /> : ""}
      {location.pathname == "/user/notification" ? <Notification /> : ""}
      {location.pathname == "/user/change-password" ? <ChangePassword /> : ""}
      {location.pathname == "/user/contact-us" ? <ContactUs /> : ""}
      {location.pathname == "/user/job-vacancy" ? <JobVacancy /> : ""}
      {location.pathname == "/user/product" ? <Product /> : ""}
      {location.pathname == "/user/job-work" ? <JobWork /> : ""}



    </Main>
  );
};

export default Sidebar;
