import React from "react";
import Index from "../../Index";

const Notification = () => {
  return (
    <div>
      <Index.Box className="main-login card-main">
        <Index.Box className="both-main profile-same">
          <Index.Box className="login-right profile-page com-profile">
            <Index.Box className="right-detail">
              <Index.Typography className="notification-title">
                Notification
              </Index.Typography>
              <Index.List>
                <Index.ListItem alignItems="flex-start">
                  <Index.ListItemAvatar>
                    <Index.Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                    />
                  </Index.ListItemAvatar>
                  <Index.ListItemText
                    primary="Brunch this weekend?"
                    secondary={
                      <React.Fragment>
                        <Index.Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Ali Connors
                        </Index.Typography>
                        {
                          " — Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia molestiae quas vel sint commodi repudiandae"
                        }
                      </React.Fragment>
                    }
                  />
                  {" 29 Min Ago"}
                </Index.ListItem>
                <Index.Divider variant="inset" component="li" />
                <Index.ListItem alignItems="flex-start">
                  <Index.ListItemAvatar>
                    <Index.Avatar
                      alt="Travis Howard"
                      src="/static/images/avatar/2.jpg"
                    />
                  </Index.ListItemAvatar>
                  <Index.ListItemText
                    primary="Summer BBQ"
                    secondary={
                      <React.Fragment>
                        <Index.Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          to Scott, Alex, Jennifer
                        </Index.Typography>
                        {" — Wish I could come, but I'm out of town this…"}
                      </React.Fragment>
                    }
                  />
                  {" 18 Hours Ago"}
                </Index.ListItem>
                <Index.Divider variant="inset" component="li" />
                <Index.ListItem alignItems="flex-start">
                  <Index.ListItemAvatar>
                    <Index.Avatar
                      alt="Cindy Baker"
                      src="/static/images/avatar/3.jpg"
                    />
                  </Index.ListItemAvatar>
                  <Index.ListItemText
                    primary="Oui Oui"
                    secondary={
                      <React.Fragment>
                        <Index.Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Sandra Adams
                        </Index.Typography>
                        {" — Do you have Paris recommendations? Have you ever…"}
                      </React.Fragment>
                    }
                  />
                 {" 2 Days Ago"}
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Notification;