import React, { useState, useEffect } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Index from "../../Index";
import { Cascader } from "antd";
import { MachiningData } from "../../../../components/menu/MachiningData";
import { ProcessData } from "../../../../components/menu/ProcessData";
import { DataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const types = ["Machining", "Processes"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditJobWork = ({ data, editId, handleClose, jobWorkApi }) => {
  const [option, setOption] = useState([]);
  const [machineData, setMachineData] = useState(MachiningData);
  const [processData, setProcessData] = useState(ProcessData);
  const [url, setUrl] = useState("");
  const [imageError, setImageError] = useState("");
  const [file, setFile] = useState("");

  const [jobWork, setJobWork] = useState({
    description: "",
    FileUpload: "",
  });
  const [select, setSelect] = useState({
    selectData: "",
    listSelectData: "",
  });
  const [productError, setProductError] = useState({
    descriptionError: "",
    // fIleuploadError: "",
  });
  const [selectError, setSelectError] = useState({
    selectDataError: "",
    listSelectDataError: "",
  });

  const fileHandler = (e) => {
    const File = e[0];
    let urllink = File.name;
    setUrl(urllink);
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setJobWork((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handleChange = (event) => {
    if (event.target.value === "Machining") {
      setOption(machineData);
    } else if (event.target.value === "Processes") {
      setOption(processData);
    }
    setSelect({
      ...select,
      selectData: event.target.value,
    });
  };

  const handleChange1 = (event) => {
    setSelect({
      ...select,

      listSelectData: event,
    });
  };
  // const imagehandler = (e) => {
  //   let urllink = window.URL.createObjectURL(e[0]);
  //   const imageFile = e[0];
  //   if (!imageFile.name.match(/\.(jpg|jpeg|JPEG|png|gif)$/)) {
  //     setImageError("Please upload valid image of jpg,jpeg,png,gif");
  //     setUrl("");
  //     return false;
  //   } else {
  //     setImageError("");
  //     setUrl(urllink);
  //   }
  // };
  const handelSubmit = async () => {
    let isSelect = Index.valueCheck(select.selectData, "Please select a type");
    let isListSelect = Index.valueCheck(
      select.listSelectData,
      "Please select a list type"
    );
    let isDescription = Index.valueCheck(
      jobWork.description,
      "Please enter description"
    );
    // let isFileUpload = Index.valueCheck(
    //   jobWork.FileUpload,
    //   "Please upload a file"
    // );

    setSelectError((prev) => ({ ...prev, selectDataError: isSelect }));
    setSelectError((prev) => ({ ...prev, listSelectDataError: isListSelect }));
    setProductError((prev) => ({ ...prev, descriptionError: isDescription }));
    // setProductError((prev) => ({ ...prev, fIleuploadError: isFileUpload }));

    if (isSelect === "" && isListSelect === "" && isDescription === "") {
      const urlencoded = new FormData();
      let fileData = file && file[0] ? [...file] : [""];

      await urlencoded.append("jobWorkId", editId);
      if (select.selectData === "Machining") {
        urlencoded.append("machining", select.listSelectData);
        urlencoded.append("machiningTag", select.selectData);
        urlencoded.append("machinigJobDescription", jobWork.description);
        urlencoded.append("machiningFiles", fileData[0]);
      } else if (select.selectData === "Processes") {
        urlencoded.append("processes", select.listSelectData);
        urlencoded.append("processesTag", select.selectData);
        urlencoded.append("processesJobDescription", jobWork.description);
        urlencoded.append("processesFiles", fileData[0]);
      }

      console.log("editIdeditId", editId);
      DataService.post(Api.User.JobWorkEdit, urlencoded)
        .then((response) => {
          if (response.data.status == 201) {
            handleClose();
            Index.toast.success(response.data.message);
            console.log("error", response.data.data);
            jobWorkApi();
          }
        })
        .catch((error) => {
          Index.toast.error(error.response.data.message);
          console.log("error", error);
        });
    }
  };
  console.log("Dharmik", data);
  useEffect(() => {
    let names = {
      description: data?.machinigJobDescription,
    };
    let processNames = {
      description: data?.processesJobDescription,
    };
    let selectData = {
      selectData: data?.machiningTag,
      listSelectData: data?.machining,
    };
    let processSelectData = {
      selectData: data?.processesTag,
      listSelectData: data?.processes,
    };
    if (selectData?.selectData === "Machining") {
      setUrl(data?.machiningFiles);
      setJobWork(names);
      setSelect(selectData);
      setOption(machineData);
    } else if (selectData.selectData === "Processes") {
      setUrl(data.processesFiles);
      setJobWork(processNames);
      setSelect(processSelectData);
      setOption(processData);
    }
  }, [data]);
  console.log("error", data);

  return (
    <div>
      <div>
        <Index.Box className="change-pw-model">
          <h2>Edit JobWork</h2>
          <Index.Box className="margin-modal">
            <Index.FormControl className="addwork-select">
              <Index.Typography className="select-jobwork">
                Select Type
              </Index.Typography>
              <Index.Select
                displayEmpty
                value={select.selectData}
                input={<OutlinedInput />}
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected?.length === 0) {
                    return (
                      <em className="addwork-select-placeholder">
                        Select Type
                      </em>
                    );
                  }
                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                error={selectError.selectDataError}
              >
                <Index.MenuItem disabled value=""></Index.MenuItem>
                {types.map((name) => (
                  <Index.MenuItem
                    className="main-job-select"
                    key={name}
                    value={name}
                  >
                    {name}
                  </Index.MenuItem>
                ))}
              </Index.Select>
            </Index.FormControl>
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {selectError.selectDataError}
            </Index.FormHelperText>
            <Index.Typography className="select-list-jobwork">
              Select List Type
            </Index.Typography>
            <Cascader
              options={option}
              onChange={handleChange1}
              value={select.listSelectData}
              error={selectError.listSelectDataError}
              changeOnSelect
              className="cascader-jobwork"
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {selectError.listSelectDataError}
            </Index.FormHelperText>
            <Index.FormControl
              sx={{ m: 1, width: "25ch" }}
              variant="outlined"
              className="model-same-input mail-input"
            >
              <Index.Box className="inq-description">
                <Index.TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  name="description"
                  placeholder="Description"
                  className="inq-description-area"
                  error={productError.descriptionError}
                  value={jobWork.description}
                  onChange={(e) => onChange(e)}
                />
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {productError.descriptionError}
                </Index.FormHelperText>
              </Index.Box>
              <Index.Box>
                <Index.Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  className="upload-bttn"
                >
                  <Index.Button
                    variant="contained"
                    component="label"
                    className="bttn-clr"
                    // error={productError.fIleuploadError}
                    value={jobWork.FileUpload}
                  >
                    Upload File
                    <input
                      multiple
                      type="file"
                      hidden
                      onChange={(e) => {
                        setFile(e.target.files);
                        fileHandler(e.target.files);
                      }}
                    />
                  </Index.Button>
                  &nbsp;&nbsp;&nbsp; {url}
                </Index.Stack>
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {imageError}
                </Index.FormHelperText>
              </Index.Box>
            </Index.FormControl>

            <Index.Box>
              <Index.BSButton
                variant="contained"
                color="primary"
                onClick={(e) => {
                  handelSubmit();
                  // imagehandler();
                }}
                className="submit-btn change-pw-btn"
                label="Submit"
              ></Index.BSButton>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </div>
  );
};

export default EditJobWork;
