import axios from "axios";

// const API_ENDPOINT = "https://api.bsetu.com/api";
const API_ENDPOINT = "https://app.bsetu.com/api/";

const DataService = axios.create({
  baseURL: API_ENDPOINT,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
});

DataService.interceptors.request.use(function (config) {
  const token = localStorage.getItem("Token");
  config.headers.Auth = token ? `${token}` : "";
  return config;
});

export { DataService, API_ENDPOINT };
