import React, { useState } from "react";
import Index from "../../Index";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

const PersonalProfilesContinue = () => {
  const [salaryError, setSalaryError] = useState("");
  const [salary, setSalary] = useState("");
  const [partTime, setPartTime] = useState("");
  const [fullTime, setfullTime] = useState("");
  const [url, setUrl] = useState("");
  const [url1, setUrl1] = useState("");

  const fileHandler = (e) => {
    const File = e[0];
    let urllink = File.name;
    setUrl(urllink);
  };

  const fileHandlerR = (e) => {
    const File = e[0];
    let urllink = File.name;
    setUrl1(urllink);
  };

  const handelSubmit = () => {
    setSalaryError(Index.valueCheck(salary, "Please enter salary"));
    let userId = localStorage.getItem("userid");
    let formData = new FormData();
    formData.append("id", userId);
    formData.append("expectedSalary", salary);

    if (fullTime == "1") {
      formData.append("workAs", fullTime);
    } else {
      formData.append("workAs", partTime);
    }

    DataService.post(Api.User.PersonalProfileStep2, formData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
            <Index.Box className="auth-inner">
              <Index.Typography
                variant="h5"
                className="page-heading-main text-left"
              >
                Personal Profile
              </Index.Typography>
              <Index.FormControl variant="standard" className="mail-input">
                <Index.BSTextField
                  id="outlined-basic"
                  label="Expected salary(Per Month)"
                  variant="outlined"
                  placeholder="Enter Salary Amount"
                  inputProps={{ autoComplete: "off" }}
                  error={salaryError === "" ? false : true}
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                />
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {salaryError}
                </Index.FormHelperText>
              </Index.FormControl>

              <Index.Box className="personal-type">
                <Index.Typography className="work-as-titles">
                  Work As
                </Index.Typography>
                <Index.Box className="companytype-chechbox">
                  <Index.FormGroup className="auth-checkbox-flex">
                    <Index.FormControlLabel
                      control={<Index.Checkbox defaultUnChecked />}
                      label="Full Time"
                      value={fullTime}
                      onChange={(e) => setfullTime("1")}
                    />
                    <Index.FormControlLabel
                      control={<Index.Checkbox defaultUnChecked />}
                      label="Part Time"
                      value={partTime}
                      onChange={(e) => setPartTime("0")}
                    />
                  </Index.FormGroup>
                </Index.Box>
              </Index.Box>

              <Index.Box className="profile-page-upload personal-profile-upload">
                <Index.Box className="resume-btn">
                  <Index.Typography className="auth-sub-titles">Upload Resume(Optional)</Index.Typography>
                  <Index.Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className="upload-bttn"
                  >
                    <Index.Button
                      variant="contained"
                      component="label"
                      className="bttn-clr"
                    >
                      Resume
                      <input
                        multiple
                        type="file"
                        hidden
                        onChange={(e) => {
                          fileHandlerR(e.target.files);
                        }}
                      />
                    </Index.Button>
                    &nbsp;&nbsp;&nbsp; {url1}
                  </Index.Stack>
                </Index.Box>
                <Index.Box>
                  <Index.Typography className="auth-sub-titles">
                    Upload Portfolio(Optional)
                  </Index.Typography>
                  <Index.Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className="upload-bttn"
                  >
                    <Index.Button
                      variant="contained"
                      component="label"
                      className="bttn-clr"
                    >
                      Portfolio
                      <input
                        multiple
                        type="file"
                        hidden
                        onChange={(e) => {
                          fileHandler(e.target.files);
                        }}
                      />
                    </Index.Button>
                    &nbsp;&nbsp;&nbsp; {url}
                  </Index.Stack>
                </Index.Box>
              </Index.Box>

              <Index.Box className="primary-btn-main personal-continue-btn">
                <Index.BSButton
                  variant="Submit"
                  className="login-btn primary-btn"
                  label="Continue "
                  onClick={() => handelSubmit()}
                ></Index.BSButton>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};
export default PersonalProfilesContinue;
