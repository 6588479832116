import { React, useState,useEffect } from "react";
import Index from "../../Index";
import { useNavigate } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditVacancy = ({handleClose,editId,data,jobVacancyApi}) => {

  const [product, setProduct] = useState({
    category: "",
    otherCategory: "",
    title: "",
    expertise: "",
    experience: "",
    description: "",
    files: "",
  });
  const [productError, setProductError] = useState({
    categoryError: "",
    otherCategoryError: "",
    titleError: "",
    expertiseError: "",
    experienceError: "",
    descriptionError: "",
    filesError: "",
  });

  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const fileHandler = (e) => {
    const File = e[0];
    console.log(File);
    let urllink = File.name;
    setUrl(urllink);
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setProduct((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handelSubmit = async () => {
    let iscategory = Index.valueCheck(
      product.category,
      "Please enter product name"
    );
    let isotherCategory = Index.valueCheck(
      product.otherCategory,
      "Please enter otherCategory"
    );
    let isTitle = Index.valueCheck(product.title, "Please enter title");
    let isExpertise = Index.valueCheck(
      product.expertise,
      "Please enter expertise"
    );
    let isExperience = Index.valueCheck(
      product.experience,
      "Please enter experience"
    );
    let isDescription = Index.valueCheck(
      product.description,
      "Please enter description"
    );
    // let isfiles = Index.valueCheck(
    //   product.files,
    //   "Please enter new product",
    // );
    setProductError((prev) => ({ ...prev, categoryError: iscategory }));
    setProductError((prev) => ({
      ...prev,
      otherCategoryError: isotherCategory,
    }));
    setProductError((prev) => ({ ...prev, titleError: isTitle }));
    setProductError((prev) => ({ ...prev, expertiseError: isExpertise }));
    setProductError((prev) => ({ ...prev, experienceError: isExperience }));
    setProductError((prev) => ({ ...prev, descriptionError: isDescription }));
    // setProductError((prev) => ({ ...prev, filesError: isfiles }));
    const urlencoded = new FormData();
    let fileData = file && file[0] ? [...file] : [""];
    urlencoded.append("category", product.category);
    urlencoded.append("otherCategory", product.otherCategory);
    urlencoded.append("title", product.title);
    urlencoded.append("expertise", product.expertise);
    urlencoded.append("experience", product.experience);
    urlencoded.append("description", product.description);
    urlencoded.append("jobVacancyId", editId);  
    urlencoded.append("files", fileData[0]);

    console.log("Kush123", fileData);
    await DataService.post(Api.User.JobVacancyEdit, urlencoded)
      .then((response) => {
        console.log("Response : ", response.data);
        if (response.data.status == 201) {
          localStorage.setItem("userid", response.data.data[0]._id);
          Index.toast.success(response.data.message);
          handleClose();
          jobVacancyApi();
        } else {
          Index.toast.error(response.data.message);
        }

      })
      .catch((error) => {
        Index.toast.error(error.response.data.message);
        console.log("HFGDGHSDFD", error);
      });
   
  };
  useEffect(() => {
    let names = {
      category: data.category,
      otherCategory: data.otherCategory,
      title: data.title,
      experience: data.experience,
      expertise: data.expertise,
      description: data.description,
    };
    setUrl(data.files);  
    setProduct(names);
  }, [data]);



  return (
    <div>
      <Index.Box sx={style} className="change-pw-model">
        <h2>Edit Vacancy</h2>
        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            category
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="category"
            name="category"
            placeholder="category"
            error={productError.categoryError}
            value={product.category}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.categoryError}
          </Index.FormHelperText>
        </Index.FormControl>

        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            otherCategory
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="otherCategory"
            name="otherCategory"
            placeholder="otherCategory"
            error={productError.otherCategoryError}
            value={product.otherCategory}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.otherCategoryError}
          </Index.FormHelperText>
        </Index.FormControl>
  <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            title
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="title"
            name="title"
            placeholder="title"
            error={productError.titleError}
            value={product.title}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.titleError}
          </Index.FormHelperText>
        </Index.FormControl>

        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            expertise
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="expertise"
            name="expertise"
            placeholder="expertise"
            error={productError.expertiseError}
            value={product.expertise}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.expertiseError}
          </Index.FormHelperText>
        </Index.FormControl>
        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            experience
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="experience"
            name="experience"
            placeholder="experience"
            error={productError.experienceError}
            value={product.experience}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.experienceError}
          </Index.FormHelperText>
        </Index.FormControl>
        <Index.FormControl
          sx={{ m: 1, width: "25ch" }}
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.Box className="inq-description">
            <Index.TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              name="description"
              placeholder="Description"
              className="inq-description-area"
              error={productError.descriptionError}
              value={product.description}
              onChange={(e) => onChange(e)}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {productError.descriptionError}
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box>
            <Index.Stack
              direction="row"
              alignItems="center"
              spacing={2}
              className="upload-bttn"
            >
              <Index.Button
                variant="contained"
                component="label"
                className="bttn-clr"
              >
                Upload File
                <input
                  accept="files/*"
                  multiple
                  type="file"
                  hidden
                  onChange={(e) => {
                    setFile(e.target.files);
                    fileHandler(e.target.files);
                  }}
                />
              </Index.Button>
              &nbsp;&nbsp;&nbsp; {url}
            </Index.Stack>
          </Index.Box>
        </Index.FormControl>

        <Index.Box>
          <Index.BSButton
            variant="contained"
            color="primary"
            onClick={() => handelSubmit()}
            className="submit-btn change-pw-btn"
            label="Submit"
          ></Index.BSButton>
        </Index.Box>
      </Index.Box>
    </div>
  );
};
export default EditVacancy;
