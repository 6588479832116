import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import moment from "moment";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Inquiry from "../../../../components/inputField/Inquiry";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["jobVacancy"];

function getStyles(name, selectData, theme) {
  return {
    fontWeight:
      selectData.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const JobList = () => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const token = localStorage.getItem("Token");
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  const [selectData, setSelectData] = useState("");
  const [jobData, setJobData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [Query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectData(value);
    const SelectFilterData = jobData.filter((item) => {
      if (item?.type == value) {
        return item;
      }
    });
    setSearchData(SelectFilterData);
  };

  useEffect(() => {
    setLoader(true);
    let fromdata = new FormData();
    fromdata.append("token", token);

    DataService.get(Api.User.list, fromdata)
      .then((response) => {
        setJobData(response.data.data);
        // localStorage.setItem("postId", response.data.data[0]._id);
        // setSearchData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const handleSearch = (event) => {
    const getSearch = event.target.value;

    if (getSearch.length > 0) {
      const FilterData = jobData.filter((item) =>
        item?.category?.toLowerCase().includes(getSearch?.toLowerCase())
      );
      setSearchData(FilterData);
    } else {
      setSearchData(jobData);
    }
    setQuery(getSearch);
  };

  const inquirySubmit = () => {
    let postId = localStorage.getItem("postId");
    let urlencoded = new URLSearchParams();
    urlencoded.append("description", description);
    urlencoded.append("postId", postId);

    DataService.post(Api.User.addEnquiry, urlencoded)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    setOpen(false);
  };

  return (
    <div>
      <Index.Box className="main-login card-main">
        <Index.Box className="both-main profile-same">
          <Index.Box className="login-right profile-page">
            <Index.Box className="right-detail update-right">
              <Index.Box className="job-header">
                <Index.Box className="job-search-select">
                  <Index.Box className="job-select">
                    <Index.FormControl
                      sx={{ m: 1, width: 300, mt: 3 }}
                      className="sub-job-select"
                    >
                      <Index.Select
                        // multiple
                        displayEmpty
                        value={selectData}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em className="job-placeholder">Select</em>;
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem disabled value=""></Index.MenuItem>
                        {names.map((name) => (
                          <Index.MenuItem
                            className="main-job-select"
                            key={name}
                            value={name}
                            style={getStyles(name, selectData, theme)}
                          >
                            {name}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                  <Index.Box className="search">
                    <Search>
                      <SearchIconWrapper>
                        <Index.SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={Query}
                        className="search-input"
                        onChange={(e) => handleSearch(e)}
                      />
                    </Search>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="static-search">
                  <Index.Button onClick={(e) => handleSearch(e)}>
                    Search
                  </Index.Button>
                </Index.Box>
              </Index.Box>

              {loader && <CircularProgress className="loader-icon" />}
              {(searchData.length > 0 ? searchData : jobData)?.map(
                (row, index) => {

                  return (
                    <>
                      <Index.Box className="job-content">
                        <Index.Box className="job-image">
                          <img
                            src={`http://35.177.56.74:3012/${row.fileUrl}`}
                            alt="job logo"
                            className=""
                          />
                        </Index.Box>
                        <Index.Box className="job-discription">
                          <Index.Typography>{row.category}</Index.Typography>
                          <Index.Typography>
                            {moment(row.createdAt).format("L")}
                          </Index.Typography>
                          <Index.Typography>{row.description}</Index.Typography>
                          <Inquiry id={row._id}/>
                        </Index.Box>
                        <Index.Box className="job-save-icon">
                          <Index.Checkbox
                            className="check-box-bookmark"
                            icon={<BookmarkBorderIcon />}
                            checkedIcon={<BookmarkIcon />}
                          />
                        </Index.Box>
                      </Index.Box>
                    </>
                  );
                }
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default JobList;
