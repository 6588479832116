import React, { useState } from "react";
import Index from "../../Index";
import png from "../../../../assets/png";
import { useParams } from "react-router-dom";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DegreeData from "../../../../components/DegreeData";

const Profile = () => {
  // const [open, setOpen] = useState(false);
  const { token } = useParams();
  const defaultProps = {
    options: DegreeData,
    getOptionLabel: (option) => option.name,
  };

  const [profile, setProfile] = useState({
    qualification: "",
    degree: "",
    expertise: "",
    experience: "",
    company: "",
  });
  const [inputList, setInputList] = useState([
    {
      experience: "",
      expertise: "",
      id: Math.random(),
    },
  ]);

  const [showError, setShowError] = useState({
    qualificationError: "",
    degreeError: "",
    expertiseError: "",
    experienceError: "",
    companyError: "",
  });

  const handleListAdd = () => {
    setInputList((pre) => {
      return [
        ...pre,
        {
          experience: "",
          expertise: "",
          id: Math.random(),
        },
      ];
    });
  };
  const handleRemoveItem = (index) => {
    let newList = [...inputList];
    let filterd = newList.filter((task, i) => task.id !== index);
    setInputList(filterd);
  };

  const tasksHandleChange = (e, index) => {
    const { value, name } = e.target;
    let arr = [...inputList];
    arr[index][name] = value;
    setInputList(arr);
  };
  const handelSubmit = async() => {
    let isQualification = Index.valueCheck(
      profile.qualification,
      "Please enter qualification"
    );
    let isDegree = Index.valueCheck(profile.degree, "Please enter degree");
    let isExpertise = Index.valueCheck(
      profile.expertise,
      "Please enter expertise"
    );
    let isExperience = Index.valueCheck(
      profile.experience,
      "Please enter experience"
    );
    let isCompany = Index.valueCheck(profile.company, "Please enter company");

    setShowError((prev) => ({ ...prev, qualificationError: isQualification }));
    setShowError((prev) => ({ ...prev, degreeError: isDegree }));
    setShowError((prev) => ({ ...prev, expertiseError: isExpertise }));
    setShowError((prev) => ({ ...prev, experienceError: isExperience }));
    setShowError((prev) => ({ ...prev, companyError: isCompany }));

    let urlencoded = new URLSearchParams();
    urlencoded.append("token", token);
    await DataService.get(Api.User.profile, urlencoded)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setProfile((data) => ({
      ...data,
      [name]: value,
    }));
  };
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>
      <Index.Box className="main-login card-main">
        
          <Index.Box className="both-main profile-same">
            <Index.Box className="login-right profile-page">
              <Index.Box className="right-detail">
                <Index.Box className="helper-textt">
                  <Index.Typography variant="h5">Profile</Index.Typography>
                  {/* <Index.BSButton variant="contained"
                    className="login-btn"
                    label="Change Password" 
                    onClick={handleOpen}></Index.BSButton> */}
                </Index.Box>
                <Index.Box className="profile-picture-details">
                  <Index.Box className="profile-pic">
                    <img
                      src={png.profile}
                      alt="login-logo"
                      className="user-logo"
                    />
                  </Index.Box>
                  <Index.Box className="profile-details">
                    <Index.Box>
                      <Index.Typography variant="p">abc</Index.Typography>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography variant="p">
                        abc@gmail.com
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Autocomplete
                  {...defaultProps}
                  id="disable-clearable"
                  disableClearable
                  renderInput={(params) => (
                    <Index.TextField
                      {...params}
                      label="Qualification"
                      name="qualification"
                      variant="standard"
                      placeholder="Select"
                      error={showError.qualificationError}
                      onSelect={(e) => onChange(e)}
                      value={profile.qualification}
                    />
                  )}
                />
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {showError.qualificationError}
                </Index.FormHelperText>
                <Index.TextField
                  label="Course Name"
                  inputProps={{ autoComplete: "off" }}
                  name="degree"
                  variant="standard"
                  placeholder="Enter Your Course Name"
                  className="degree-width"
                  onChange={(e) => onChange(e)}
                  error={showError.degreeError}
                  value={profile.degree}
                />

                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {showError.degreeError}
                </Index.FormHelperText>

                {inputList.length > 0
                  ? inputList.map((item, index) => {
                      return (
                        // add more fields
                        <div key={0}>
                          <Index.Box className="personal-ex exep profile-ex">
                            <Index.Box className="expertise">
                              <Index.TextField
                                inputProps={{ autoComplete: "off" }}
                                className="expertise-field"
                                id="standard-password-input"
                                label="Expertise"
                                variant="standard"
                                placeholder="XYZ"
                                value={inputList[index].expertise}
                                onChange={(e) => tasksHandleChange(e, index)}
                                name="expertise"
                              />
                            </Index.Box>
                            <Index.Box className="expertise">
                              <Index.TextField
                                inputProps={{ autoComplete: "off" }}
                                className=" text-width  expertise-field"
                                id="standard-password-input"
                                label="Experience"
                                variant="standard"
                                placeholder="2 year"
                                name="experience"
                                value={inputList[index].experience}
                                onChange={(e) => tasksHandleChange(e, index)}
                              />
                            </Index.Box>
                            {index >= 1 ? (
                              <RemoveCircleOutlineIcon
                                onClick={() => handleRemoveItem(item.id)}
                              />
                            ) : (
                              ""
                            )}
                          </Index.Box>
                        </div>
                      );
                    })
                  : " "}

                <Index.Box className="personal-pro-btn1">
                  <Index.BSButton
                    variant="Addmore"
                    className="add-more-btn"
                    label="Add More"
                    onClick={handleListAdd}
                  />
                </Index.Box>

                <Index.Box className="curr-work">
                  <Index.Typography variant="p" className="current-work-text">
                    Current Working (Company Name)
                  </Index.Typography>
                  <Index.BSTextField
                    id="outlined-basic" 
                    variant="outlined"
                    name="company"
                    onChange={(e) => onChange(e)}
                    error={showError.companyError}
                    value={profile.company}
                    placeholder="current company name"
                    inputProps={{ autoComplete: "off" }}
                  />
                  <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.companyError}
                  </Index.FormHelperText>
                </Index.Box>
                <Index.Box className="right-Next-btn personal-pro-btn profile-next">
                  <Index.BSButton
                    variant="Submit"
                    className="login-btn"
                    label="Update"
                    onClick={() => handelSubmit()}
                  ></Index.BSButton>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        > 
        <AddProduct handleClose={handleClose}/>
        </Modal> */}
       
      </Index.Box>
    </div>
  );
};


export default Profile;
