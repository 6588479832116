import { React, useState } from "react";
import Index from "../../Index";
import { DataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  p: 4,
};

const AddProduct = ({handleClose,getProductList}) => {
  const [product, setProduct] = useState({
    productName: "",
    tags: "",
    description: "",
    FileUpload: "",
  });
  const [productError, setProductError] = useState({
    productNameError: "",
    tagsError: "",
    descriptionError: "",
    fIleuploadError: "",
  });


  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [imageError, setImageError] = useState("");


  const imagehandler = (e) => {
    let urllink = window.URL.createObjectURL(e[0]);
    const imageFile = e[0];
    if (!imageFile.name.match(/\.(jpg|jpeg|JPEG|png|gif)$/)) {
      setImageError("Please upload valid image of jpg,jpeg,png,gif");
      setUrl("");
      return false;
    } else {
      setImageError("");
      setUrl(urllink);
    }
  };
  const fileHandler = (e) => {
    const File = e[0];
    console.log(File);
    let urllink = File.name;
    setUrl(urllink);
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setProduct((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handelSubmit = async () => {
    let isProductName = Index.valueCheck(
      product.productName,
      "Please enter product name"
    );
    let isTags = Index.valueCheck(product.tags, "Please enter tags");
    let isDescription = Index.valueCheck(
      product.description,
      "Please enter description"
    );
    // let isFileUpload = Index.valueCheck(
    //   product.FileUpload,
    //   "Please enter new product",
    // );
    setProductError((prev) => ({ ...prev, productNameError: isProductName }));
    setProductError((prev) => ({ ...prev, tagsError: isTags }));
    setProductError((prev) => ({ ...prev, descriptionError: isDescription }));
    // setProductError((prev) => ({ ...prev, fIleuploadError: isFileUpload }));
    const urlencoded = new FormData();
    let fileData = file && file[0] ? [...file] : [""];
    urlencoded.append("productName", product.productName);
    urlencoded.append("tags", product.tags);
    urlencoded.append("description", product.description);
    urlencoded.append("files", fileData[0]);
    
    
    await DataService.post(Api.User.addProduct, urlencoded)
      .then((response) => {
        console.log("Response : ", response.data);
        if (response.data.status == 201) {
          handleClose();
          localStorage.setItem("userid", response.data.data[0]._id);
          Index.toast.success(response.data.message);
       
        } else {
          Index.toast.error(response.data.message);
        }
      })
      .catch((error) => {
        Index.toast.error(error.response.data.message);
        console.log("error", error);
      });
      await getProductList();
  };

  return (
    <div>
      <Index.Box sx={style} className="change-pw-model">
        <Index.Typography className="modal-title">Add product</Index.Typography>
        <Index.FormControl
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            Product Name
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="Product Name"
            name="productName"
            placeholder="Product Name"
            error={productError.productNameError}
            value={product.productName}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.productNameError}
          </Index.FormHelperText>
        </Index.FormControl>

        <Index.FormControl
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.InputLabel
            htmlFor="outlined-adornment-product"
            inputProps={{ autoComplete: "off" }}
          >
            Tags
          </Index.InputLabel>
          <Index.OutlinedInput
            id="outlined-adornment-product"
            label="Tags"
            name="tags"
            placeholder="Tags"
            error={productError.tagsError}
            value={product.tags}
            onChange={(e) => onChange(e)}
          />
          <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
            {productError.tagsError}
          </Index.FormHelperText>
        </Index.FormControl>

        <Index.FormControl
          variant="outlined"
          className="model-same-input mail-input"
        >
          <Index.Box className="inq-description">
            <Index.TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              name="description"
              placeholder="Description"
              className="inq-description-area"
              error={productError.descriptionError}
              value={product.description}
              onChange={(e) => onChange(e)}
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {productError.descriptionError}
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box>
            <Index.Stack
              direction="row"
              alignItems="center"
              spacing={2}
              className="upload-bttn product-upload-btn"
              error={productError.productNameError}
            >
              <Index.Button
                variant="contained"
                component="label"
                className="bttn-clr"
              
              >
                Upload File
                <input
                  accept="files/*"
                  multiple
                  type="file"
                  hidden
                  onChange={(e) => {
                    setFile(e.target.files);
                    fileHandler(e.target.files);
                  }}
                />
              </Index.Button>
              &nbsp;&nbsp;&nbsp; {url}
            </Index.Stack>
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {imageError}
            </Index.FormHelperText>
          </Index.Box>
        </Index.FormControl>

        <Index.Box className="primary-btn-main modal-btn">
          <Index.BSButton
            variant="contained"
            color="primary"
            onClick={() =>{ 
              handelSubmit();
              imagehandler();
            }}
            className="submit-btn change-pw-btn primary-btn"
            label="Submit"
          ></Index.BSButton>
        </Index.Box>
      </Index.Box>
    </div>
  );
};
export default AddProduct;
