import React,{useState} from "react";
import Index from "../../container/user/Index";
import{ Link } from "react-router-dom";

const CompanyOptions = () => {
  const [open, setOpen] = useState(false);
  const handelChange = () => {

  }
  return (
    <div>
     
      <Index.Accordion className="setting-main">
        <Index.AccordionSummary
          expandIcon={<Index.ExpandMoreIcon open={open}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="setting-sub"
        >
          <Index.Typography className="setting-main-data">
            Portfolio
          </Index.Typography>
        </Index.AccordionSummary>
        <Index.AccordionDetails className="setting-dropdown">
          
          <Link to="/user/product" className="dropdown-link">
            <Index.Typography classname="setting-dropdown-item">
              Product{" "}
            </Index.Typography>
            <Index.ArrowForwardIosIcon className="profile-setting-icon" />
          </Link>
          <Link to="/user/job-work" className="dropdown-link" >
            <Index.Typography classname="setting-dropdown-item dropdown-link">
            Job Work
            </Index.Typography>
            <Index.ArrowForwardIosIcon className="profile-setting-icon" />
            </Link>
          <Link to="/user/job-vacancy" className="dropdown-link">
            <Index.Typography classname="setting-dropdown-item">
            Vacancy
            </Index.Typography>
            <Index.ArrowForwardIosIcon className="profile-setting-icon" />
          </Link>
        </Index.AccordionDetails>
      </Index.Accordion>
    </div>
  );
};

export default CompanyOptions;
