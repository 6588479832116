import { Box, Typography, styled } from "@mui/material";

export const StyledSwitchContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: 10,
}));

export const StyledTypeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: `#F3F3F3`,
  borderRadius: 10,
  cursor: "pointer",
  width: "25%",
  marginRight: 20,
  [theme.breakpoints.down("sm")]: {
    width: "40%",
  },
}));

export const StyledSwitch = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: "bold",
  color: `#464646`,
}));
