import React from "react";
import '../src/assets/css/index.css'
import '../src/assets/css/responsive.css'
import Routes from '../src/routes/routes'


const App =() => {

  return <Routes />;
  
}


export default App;
