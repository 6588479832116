import React from 'react'
import JobList from '../jobList/JobList'

const PersonHome = () => {
  return (
    <div>
        <JobList/>
    </div>
  )
}

export default PersonHome