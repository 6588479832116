const DegreeData = [
    {
      id: 1,
      name: 'Engineering',
    },
    {
      id: 2,
      name: 'Medical',
    },
    {
      id: 3,
      name: 'Pharmacy',
    },
    {
      id: 4,
      name: 'B.Com',
    },
    {
      id: 5,
      name: 'BCA',
    },
    {
      id: 6,
      name: 'BBA',
    },
    {
      id: 7,
      name: 'BSc',
    },
    {
      id: 8,
      name: 'Law',
    },
    {
      id: 9,
      name: 'Others',
    },
  ];
  export default  DegreeData;