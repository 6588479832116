const Api = {
  User: {
    CompanyRegisterStep1: "companyRegisterStep1",
    CompanyRegisterStep2: "companyRegisterStep2",
    CompanyRegisterStep3: "companyRegisterStep3",
    PersonalProfile: "personalProfile",
    PersonalProfileStep2: "personalProfileStep2",
    resendOtp: "resendOtp",
    login: "login",
    profile: "profile",
    verifyOtp: "verifyOtp",
    updateProfile: "profile/update",
    list: "list",
    changePassword: "user/changePassword",
    zoneMaster: "user/zone",
    JobvacancyList: "jobVacancy/list",
    ProductList: "product/list",
    JobWorkList: "jobWork/list",
    addEnquiry: "addEnquiry",
    addProduct: "addProduct",
    PostJobVacancy: "postJobVacancy",
    JobVacancyEdit: "jobVacancy/edit",
    ProductEdit: "product/edit",
    addJobWork: "addJobWork",
    ProductRemove: "product/remove",
    JobWorkRemove: "jobWork/remove",
    JobVacancyRemove: "jobVacancy/remove",
    JobWorkEdit: "jobWork/edit",
    PortalRegister: "portal-register",
    QualificationData: "/admin/qualifications",
  },
};
export { Api };
