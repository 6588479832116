import React, { useState } from "react";
import Index from "../../Index";
import { useNavigate } from "react-router-dom";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

const CompanyProfiles = () => {
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    email: "",
    number: "",
    address: "",
    ownerName: "",
    legalFirm: "",
  });
  const [showError, setShowError] = useState({
    gstError: "",
    numberError: "",
    addressError: "",
    ownerNameError: "",
    legalFirmError: "",
  });

  const handelSubmit = () => {
    let isOwner = Index.valueCheck(
      company.ownerName,
      "Please enter owner name"
    );
    let isLegalFirm = Index.valueCheck(
      company.legalFirm,
      "Please enter legal firm name"
    );
    let isnumber = Index.numberCheck(
      company.number,
      "Please enter mobile number",
      "Please enter a valid number"
    );
    let isAddress = Index.valueCheck(company.address, "Please enter address");
    let isEmail = Index.emailCheck(
      company.email,
      "Please enter email",
      "Please enter valid email"
    );
    let userId = localStorage.getItem("userid");

    setShowError((prev) => ({ ...prev, ownerNameError: isOwner }));
    setShowError((prev) => ({ ...prev, emailError: isEmail }));
    setShowError((prev) => ({ ...prev, addressError: isAddress }));
    setShowError((prev) => ({ ...prev, legalFirmError: isLegalFirm }));
    setShowError((prev) => ({ ...prev, numberError: isnumber }));

    let urlencoded = new URLSearchParams();
    urlencoded.append("id", userId);
    urlencoded.append("legalFirmName", company.legalFirm);
    urlencoded.append("ownerName", company.ownerName);
    urlencoded.append("companyMobileNumber", company.number);
    urlencoded.append("companyEmail", company.email);
    urlencoded.append("address", company.address);

    DataService.post(Api.User.CompanyRegisterStep2, urlencoded)
      .then((response) => {
        if (
          isEmail === "" &&
          isnumber === "" &&
          isLegalFirm === "" &&
          isOwner === "" &&
          isAddress === ""
        ) {
          navigate("/company-detail");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setCompany((data) => ({
      ...data,
      [name]: value,
    }));
  };

  return (
    <Index.Box>
      <Index.Box>
        <Index.Box className="login-main">
          <Index.Logo />
          <Index.Box className="login-right-main">
            <Index.Box className="auth-inner company-profile-inner">
              <Index.Typography variant="h5" className="page-heading-main text-left">
                Company Profile
              </Index.Typography>

              <Index.Box className="grid-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="standard"
                          className="mail-input"
                        >
                          <Index.BSTextField
                            id="outlined-basic"
                            label="Legal Firm Name"
                            variant="outlined"
                            placeholder="Enter legal firm name"
                            inputProps={{ autoComplete: "off" }}
                            name="legalFirm"
                            error={showError.legalFirmError}
                            onChange={(e) => onChange(e)}
                            value={company.legalFirm}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.legalFirmError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="standard"
                          className="mail-input "
                        >
                          <Index.BSTextField
                            id="outlined-basic"
                            label="Owner Name"
                            variant="outlined"
                            placeholder="Enter owner name(company contact)"
                            inputProps={{ autoComplete: "off" }}
                            name="ownerName"
                            error={showError.ownerNameError}
                            onChange={(e) => onChange(e)}
                            value={company.ownerNameError}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.legalFirmError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="standard"
                          className="mail-input"
                        >
                          <Index.BSTextField
                            id="outlined-basic"
                            label="Mobile Number"
                            variant="outlined"
                            placeholder="Enter mobile number"
                            inputProps={{ autoComplete: "off" }}
                            name="number"
                            error={showError.numberError}
                            onChange={(e) => onChange(e)}
                            value={company.number}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.numberError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="standard"
                          className="mail-input "
                        >
                          <Index.BSTextField
                            id="outlined-basic"
                            label="Company Email"
                            variant="outlined"
                            placeholder="Enter company mail"
                            inputProps={{ autoComplete: "off" }}
                            name="email"
                            error={showError.emailError}
                            onChange={(e) => onChange(e)}
                            value={company.email}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.emailError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="auth-input-main">
                        <Index.FormControl
                          variant="standard"
                          className="mail-input"
                        >
                          <Index.BSTextField
                            id="outlined-basic"
                            label="Address"
                            variant="outlined"
                            placeholder="Enter Address"
                            inputProps={{ autoComplete: "off" }}
                            name="address"
                            error={showError.addressError}
                            onChange={(e) => onChange(e)}
                            value={company.address}
                          />
                          <Index.FormHelperText
                            sx={{ margin: "3px", color: "red" }}
                          >
                            {showError.addressError}
                          </Index.FormHelperText>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="company-profile-btn-main primary-btn-main">
                        <Index.BSButton
                          onClick={() => handelSubmit()}
                          variant="Submit"
                          className="login-btn primary-btn"
                          label="Next "
                        ></Index.BSButton>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default CompanyProfiles;
