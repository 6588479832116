import React, { useEffect, useState } from "react";
import Index from "../../Index";
import png from "../../../../assets/png";
import AddIcon from "@mui/icons-material/Add";
import { Api } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

const UpdateProfile = () => {

  const [data, setData] = useState({})
  const [profileUp, setProfileUp] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
  });

  const [showError, setShowError] = useState({
    firstNameError: "",
    lastNameError: "",
    mobileError: "",
    emailError: "",
  });
  const [image, setImage] = useState(png.profile);
  const [url, setURL] = useState(png.profile);

  const onImageChange = (event) => {
    console.log(event.target.files[0], 29);
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
      setURL(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setProfileUp((data) => ({
      ...data,
      [name]: name === "mobileNumber" ? value.slice(0, 10) : value,
    }));
  };


  const profileData = () => {
    // let urlencoded = new URLSearchParams();
    DataService.get(Api.User.profile)
      .then((response) => {
        console.log(response , 45)
        // setType(response.data.data[0].registerType);
        setData(response.data.data[0])
        // if (response.data.status === 200) {
          // setTimeout(() => {
            // navigate("/");
          // }, 2000);
        // }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    profileData()
  },[])

  const handelSubmit = () => {
    let isFirstName = Index.valueCheck(
      profileUp.firstName,
      "Please enter first name"
    );
    let isLastName = Index.valueCheck(
      profileUp.lastName,
      "Please enter last name"
    );
    let isEmail = Index.emailCheck(
      profileUp.email,
      "Please enter email",
      "Please enter valid email"
    );
    let isMobile = Index.numberCheck(
      profileUp.mobileNumber,
      "Please enter mobile number",
      "Please enter a valid number"
    );

    setShowError((prev) => ({ ...prev, firstNameError: isFirstName }));
    setShowError((prev) => ({ ...prev, lastNameError: isLastName }));
    setShowError((prev) => ({ ...prev, emailError: isEmail }));
    setShowError((prev) => ({ ...prev, mobileError: isMobile }));

    let formdata = new FormData();
    formdata.append("firstName", profileUp.firstName);
    formdata.append("lastName", profileUp.lastName);
    formdata.append("email", profileUp.email);
    formdata.append("mobileNumber", profileUp.mobileNumber);
    formdata.append("profile", image);

    if (
      isFirstName === "" &&
      isLastName === "" &&
      isEmail === "" &&
      isMobile === ""
    ) {
      DataService.post(Api.User.updateProfile, formdata)
        .then((response) => {
          Index.toast.success(response.data.message);
        })
        .catch((error) => {
          Index.toast.error(error.response.data.message);
        });
    }
  };
  console.log(data,"jush")
 useEffect(() => {
    let profileData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobileNumber: data.mobileNumber,
    };

    console.log(profileData , "profileData")
      setProfileUp(profileData);
  }, [data]);
  return (
    <div>
      <Index.Box className="main-login card-main">

          <Index.Box className="both-main profile-same">
            <Index.Box className="login-right profile-page">
              <Index.Box className="right-detail update-right">
                <Index.Box className="file-upload-btn-main">
                  <img
                    src={url}
                    alt="login-logo"
                    className="upload-profile-img"
                  />
                  <Index.Button component="label">
                    <AddIcon className="upload-icon-img" />
                    <input
                      hidden
                      accept="image/*"
                      onChange={onImageChange}
                      multiple
                      type="file"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="profile-input-main">
                  <Index.FormControl
                    variant="standard"
                    className="mail-input-profile"
                  >
                    <Index.BSTextField
                      label="Frist Name"
                      name="firstName"
                      variant="standard"
                      placeholder="Enter Your Frist Name"
                      className="degree-width"
                      onChange={(e) => onChange(e)}
                      error={showError.firstNameError}
                      value={profileUp.firstName}
                      inputProps={{ autoComplete: "off" }}
                    />
                    <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                      {showError.firstNameError}
                    </Index.FormHelperText>
                  </Index.FormControl>
                  <Index.FormControl
                    variant="standard"
                    className="mail-input-profile"
                  >
                    <Index.BSTextField
                      label="Last Name"
                      name="lastName"
                      variant="standard"
                      placeholder="Enter Your Last Name"
                      className="degree-width"
                      onChange={(e) => onChange(e)}
                      error={showError.lastNameError}
                      value={profileUp.lastName}
                      inputProps={{ autoComplete: "off" }}
                    />
                    <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                      {showError.lastNameError}
                    </Index.FormHelperText>
                  </Index.FormControl>

                  <Index.FormControl
                    variant="standard"
                    className="mail-input-profile"
                  >
                    <Index.BSTextField
                      label="Email"
                      name="email"
                      variant="standard"
                      placeholder="Enter Your Email"
                      className="degree-width"
                      onChange={(e) => onChange(e)}
                      error={showError.emailError}
                      value={profileUp.email}
                      inputProps={{ autoComplete: "off" }}
                    />
                    <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                      {showError.emailError}
                    </Index.FormHelperText>
                  </Index.FormControl>

                  <Index.FormControl
                    variant="standard"
                    className="mail-input-profile"
                  >
                    <Index.BSTextField
                      label="Mobile Number"
                      name="mobileNumber"
                      variant="standard"
                      placeholder="Enter Your Mobile Number"
                      className="degree-width"
                      onChange={(e) => onChange(e)}
                      error={showError.mobileError}
                      value={profileUp.mobileNumber}
                      inputProps={{ autoComplete: "off" }}
                    />
                    <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                      {showError.mobileError}
                    </Index.FormHelperText>
                  </Index.FormControl>
                </Index.Box>

                <Index.Box className="right-Next-btn personal-pro-btn profile-next">
                  <Index.BSButton
                    onClick={() => handelSubmit()}
                    variant="Submit"
                    className="login-btn"
                    label="Update"
                  ></Index.BSButton>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.ToastContainer />
          </Index.Box>
    
      </Index.Box>
    </div>
  );
};

export default UpdateProfile;
