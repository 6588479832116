import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Index from "../../Index";
import { Cascader } from "antd";
import { MachiningData } from "../../../../components/menu/MachiningData";
import { ProcessData } from "../../../../components/menu/ProcessData";
import { DataService } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const types = ["Machining", "Processes"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddJobWork = ({ handleClose, jobWorkApi }) => {
  const [option, setOption] = useState([]);
  const [machineData, setMachineData] = useState(MachiningData);
  const [processData, setProcessData] = useState(ProcessData);
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");

  const [jobWork, setJobWork] = useState({
    description: "",
    fileUpload: "",
    selectData: "",
    listSelectData: "",
  });
  const [selectError, setSelectError] = useState({
    descriptionError: "",
    selectDataError: "",
    listSelectDataError: "",
    fileUploadError:"",
  });

  const fileHandler = (e) => {
    const File = e[0];

    let urllink = File.name;
    setUrl(urllink);
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setJobWork((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handleChange = (event) => {
    if (event.target.value === "Machining") {
      setOption(machineData);
    } else if (event.target.value === "Processes") {
      setOption(processData);
    }
    setJobWork({
      ...jobWork,
      selectData: event.target.value,
    });
  };

  const handleChange1 = (event) => {
    setJobWork({
      ...jobWork,
      listSelectData: event,
    });
  };

  const handelSubmit = async () => {
    let isSelect = Index.valueCheck(jobWork.selectData, "Please select a type");
    let isListSelect = Index.valueCheck(
      jobWork.listSelectData,
      "Please select a list type"
    );
    let isDescription = Index.valueCheck(
      jobWork.description,
      "Please enter description"
    );
    let isFileUpload = Index.valueCheck(
      jobWork.fileUpload,
      "Please upload a file"
    );

    setSelectError((prev) => ({ ...prev, selectDataError: isSelect }));
    setSelectError((prev) => ({ ...prev, listSelectDataError: isListSelect }));
    setSelectError((prev) => ({ ...prev, descriptionError: isDescription }));
    setSelectError((prev) => ({ ...prev, fileUploadError: isFileUpload }));

    if (isSelect === "" && isListSelect === "" && isDescription === ""&& isFileUpload === "") {
      const urlencoded = new FormData();
      let fileData = file && file[0] ? [...file] : [""];
      console.log(jobWork.listSelectData, " jobWork.listSelectData ");

      if (jobWork.selectData === "Machining") {
        urlencoded.append("machining", jobWork.listSelectData);
        urlencoded.append("machiningTag", jobWork.selectData);
        urlencoded.append("machinigJobDescription", jobWork.description);
        urlencoded.append("machiningFiles", fileData[0]);
      } else if (jobWork.selectData === "Processes") {
        urlencoded.append("processes", jobWork.listSelectData);
        urlencoded.append("processesTag", jobWork.selectData);
        urlencoded.append("processesJobDescription", jobWork.description);
        urlencoded.append("processesFiles", fileData[0]);
      }

      await DataService.post(Api.User.addJobWork, urlencoded)
        .then((response) => {
          if (response.data.status == 201) {
            handleClose();
            console.log("responce", response.data.data);
            Index.toast.success(response.data.message);
          }
        })
        .catch((error) => {
          Index.toast.error(error.response.data.message);
          console.log("error", error);
        });
    }
    await setJobWork({
      description: "",
      FileUpload: "",
      selectData: "",
      listSelectData: "",
    });
    setUrl('')
    jobWorkApi();
  };

  return (
    <div>
      <div>
        <Index.Box className="change-pw-model">
          <h2>Add JobWork</h2>
          <Index.Box className="margin-modal">
            <Index.FormControl className="addwork-select">
              <Index.Typography className="select-jobwork">
                Select Type
              </Index.Typography>
              <Index.Select
                input={<OutlinedInput />}
                onChange={handleChange}
                renderValue={(selected) => {
                  console.log(selected , 149 , jobWork)
                  if (jobWork.selectData == '' || selected?.length === 0) {
                    return (
                      <em className="addwork-select-placeholder">
                        Select Type
                      </em>
                    );
                  }
                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
                error={selectError.selectDataError}
              >
                <Index.MenuItem disabled value=""></Index.MenuItem>
                {types.map((name) => (
                  <Index.MenuItem
                    className="main-job-select"
                    key={name}
                    value={name}
                  >
                    {name}
                  </Index.MenuItem>
                ))}
              </Index.Select>
            </Index.FormControl>
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {selectError.selectDataError}
            </Index.FormHelperText>
            <Index.Typography className="select-list-jobwork">
              Select List Type
            </Index.Typography>
            <Cascader
              options={option}
              onChange={handleChange1}
              value={jobWork?.listSelectData}
              error={selectError.listSelectDataError}
              changeOnSelect
              className="cascader-jobwork"
            />
            <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
              {selectError.listSelectDataError}
            </Index.FormHelperText>
            <Index.FormControl
              sx={{ m: 1, width: "25ch" }}
              variant="outlined"
              className="model-same-input mail-input"
            >
              <Index.Box className="inq-description">
                <Index.TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  name="description"
                  placeholder="Description"
                  className="inq-description-area"
                  error={selectError.descriptionError}
                  value={jobWork?.description}
                  onChange={(e) => onChange(e)}
                />
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                  {selectError.descriptionError}
                </Index.FormHelperText>
              </Index.Box>
              <Index.Box>
                <Index.Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  className="upload-bttn"
                >
                  <Index.Button
                    variant="contained"
                    component="label"
                    className="bttn-clr"
                    error={selectError.fileUploadError}
                    value={jobWork?.fileUpload}
                  >
                    Upload File
                    <input
                      multiple
                      type="file"
                      hidden
                      onChange={(e) => {
                        setFile(e.target.files);
                        fileHandler(e.target.files);
                      }}
                    />
                  </Index.Button>
                  &nbsp;&nbsp;&nbsp; {url}
                </Index.Stack>
                <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                {selectError.fileUploadError}
              </Index.FormHelperText>
              </Index.Box>
            </Index.FormControl>

            <Index.Box>
              <Index.BSButton
                variant="contained"
                color="primary"
                onClick={() => handelSubmit()}
                className="submit-btn change-pw-btn"
                label="Submit"
              ></Index.BSButton>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </div>
  );
};

export default AddJobWork;
