import React, { useEffect, useState } from "react";
import Index from '../../container/user/Index'
import Modal from "@mui/material/Modal";
import { Api } from "../../config/Api";
import { DataService } from "../../config/DataService";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  

const Inquiry = (props) => {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [description, setDescription] = useState("");
    const [open, setOpen] = useState(false);
    const [showError, setShowError] = useState({})


  const inquirySubmit = () => {

    let isDescription = Index.valueCheck(
      description,
      "Please enter your Query"
    );

    setShowError((prev) => ({ ...prev, DescriptionError : isDescription }));

    if (isDescription === "" ) {
    let urlencoded = new URLSearchParams();
    urlencoded.append("description", description);
    urlencoded.append("postId",  props.id);

    DataService.post(Api.User.addEnquiry, urlencoded)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    setOpen(false);
    }
  };


  return (
    <div>
         <Index.Box className="inquiry-btn">
                <Index.Button onClick={handleOpen}>Inquiry</Index.Button>
              </Index.Box>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Index.Box sx={style}>
                  <Index.Box className="inq-title">
                    {" "}
                    <h2>Inquiry</h2>
                  </Index.Box>
                  <Index.Box className="inq-description">
                    <Index.TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Description"
                      className="inq-description-area"
                      error={showError.DescriptionError}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <Index.FormHelperText sx={{ margin: "3px", color: "red" }}>
                    {showError.DescriptionError}
                  </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="inquiry-btn">
                    <Index.Button onClick={(e) => inquirySubmit(e)}>
                      OK
                    </Index.Button>
                    <Index.Button onClick={handleClose}>
                      cancle
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Modal>
              
    </div>
  )
}

export default Inquiry