export const MachiningData = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "Circular Band saw",
    value: "Circular Band saw",

    children: [
      {
        label: "Angular",
        value: "Angular",
      },
      {
        label: "Pillar",
        value: "Pillar",
      },
    ],
  },
  {
    label: "Lathe",
    value: "Lathe",

    children: [
      {
        label: "Light duty",
        value: "Light duty",
      },
      {
        label: "Medium duty",
        value: "Medium duty",
      },
      {
        label: "Heavy duty",
        value: "Heavy duty",
      },
    ],
  },
  {
    label: "VTL",
    value: "VTL",

    children: [
      {
        label: "Conventional",
        value: "Conventional",
      },
      {
        label: "CNC",
        value: "CNC",
      },
    ],
  },
  {
    label: "Drilling",
    value: "Drilling",

    children: [
      {
        label: "Pillar",
        value: "Pillar",
      },
      {
        label: "Radial",
        value: "Radial",
      },
      {
        label: "CNC Drill",
        value: "CNC Drill",
      },
    ],
  },
  {
    label: "Shaping",
    value: "Shaping",
  },
  {
    label: "Planer",
    value: "Planer",
  },
  {
    label: "Milling",
    value: "Milling",

    children: [
      {
        label: "DRO",
        value: "DRO",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
    ],
  },
  {
    label: "Grinding machine",
    value: "Grinding machine",

    children: [
      {
        label: "Cylindrical",
        value: "Cylindrical",

        children: [
          {
            label: "Centerless",
            value: "Centerless",
          },
          {
            label: "Internal",
            value: "Internal",
            children: [
              {
                label: "CNC",
                value: "CNC",
              },
              {
                label: "Conventional",
                value: "Conventional",
              },
            ],
          },
          {
            label: "External",
            value: "External",
            children: [
              {
                label: "CNC",
                value: "CNC",
              },
              {
                label: "Conventional",
                value: "Conventional",
              },
            ],
          },
        ],
      },
      {
        label: "Surface Grinding",
        value: "Surface Grinding",
        children: [
          {
            label: "Rotary table",
            value: "Rotary table",
          },
          {
            label: "Linear table",
            value: "Linear table",
          },
        ],
      },
    ],
  },
  {
    label: "Gear Shaper",
    value: "Gear Shaper",

    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
    ],
  },
  {
    label: "Hobbing",
    value: "Hobbing",

    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
    ],
  },
  {
    label: "Slotting",
    value: "Slotting",

    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
    ],
  },
  {
    label: "Boring",
    value: "Boring",
  },
  {
    label: "Plano miller",
    value: "Plano miller",

    children: [
      {
        label: "CNC",
        value: "CNC",
      },
      {
        label: "Conventional",
        value: "Conventional",
      },
    ],
  },
  {
    label: "Honing",
    value: "Honing",
  },
  {
    label: "Gun drilling/Deep hole drilling",
    value: "Gun drilling/Deep hole drilling",
  },
  {
    label: "CNC Turning",
    value: "CNC Turning",
  },
  {
    label: "HMC",
    value: "HMC",

    children: [
      {
        label: "Fixed Table",
        value: "Fixed Table",
      },
      {
        label: "Rotating Table",
        value: "Rotating Table",
      },
    ],
  },
  {
    label: "VMC",
    value: "VMC",

    children: [
      {
        label: "3 axis",
        value: "3 axis",

        children: [
          {
            label: "Small (Below 600mm)",
            value: "Small (Below 600mm)",
          },
          {
            label: "Medium (Below 1200mm)",
            value: "Medium (Below 1200mm)",
          },
          {
            label: "Large (Above 1500mm)",
            value: "Large (Above 1500mm)",
          },
        ],
      },
      {
        label: "4 axis",
        value: "4 axis",

        children: [
          {
            label: "Small (Below 600mm)",
            value: "Small (Below 600mm)",
          },
          {
            label: "Medium (Below 1200mm)",
            value: "Medium (Below 1200mm)",
          },
          {
            label: "Large (Above 1500mm)",
            value: "Large (Above 1500mm)",
          },
        ],
      },
      {
        label: "5 axis",
        value: "5 axis",
      },
      {
        label: "5 axis",
        value: "5 axis",
      },
    ],
  },
  {
    label: "Tool and Cutter Grinder",
    value: "Tool and Cutter Grinder",
  },
  {
    label: "EDM/Spark",
    value: "EDM/Spark",
  },
  {
    label: "EDM Drill",
    value: "EDM Drill",
  },
  {
    label: "Wire cut",
    value: "Wire cut",

    children: [
      {
        label: "Single use",
        value: "Single use",
      },
      {
        label: "Reuse",
        value: "Reuse",
      },
    ],
  },
  {
    label: "Traub",
    value: "Traub",
  },
  {
    label: "Thread milling",
    value: "Thread milling",
  },
  {
    label: "Laser cutting",
    value: "Laser cutting",
  },
  {
    label: "Plasma cutting",
    value: "Plasma cutting",
  },
  {
    label: "Threading",
    value: "Threading",
  },
  {
    label: "Laser Marking",
    value: "Laser Marking",
  },
  {
    label: "Sheet cutting",
    value: "Sheet cutting",
  },
  {
    label: "CNC Turning",
    value: "CNC Turning",

    children: [
      {
        label: "Small (below 200mm Chuck)",
        value: "Small (below 200mm Chuck)",
      },
      {
        label: "Medium (below 300mm Chuck)",
        value: "Medium (below 300mm Chuck)",
      },
      {
        label: "Large (Above 300mm Chuck)",
        value: "Large (Above 300mm Chuck)",
      },
    ],
  },
  {
    label: "Others",
    value: "Others",
  },
];
