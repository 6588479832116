let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailCheck = (val, msg1, msg2, msg3) => {
  if (val === "") {
    return msg1;
  } else if (!regEmail.test(val)) {
    return msg2;
  } else if (!regValueMax.test(val)) {
    return msg3;
  } else {
    return "";
  }
};

let regPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
export const passwordCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (!regPassword.test(val)) {
    return msg2;
  } else {
    return "";
  }
};

export const confirmPasswordCheck = (val, val1, msg1, msg2, msg3) => {
  if (val === "") {
    return msg1;
  } else if (!regPassword.test(val)) {
    return msg2;
  } else if (val !== val1) {
    return msg3;
  } else {
    return "";
  }
};

export const numberCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (val.length !== 10) {
    return msg2;
  } else {
    return "";
  }
};

export const valueCheck = (val, msg1) => {
  if (val === "") {
    return msg1;
  } else {
    return "";
  }
};

let regValue = /^[^-\s][a-zA-Z\s-]+$/;
let regValueMax = /^(.{0,100})$/;

export const maxCharacter = (val, msg1) => {
  if (!regValueMax.test(val)) {
    return msg1;
  } else {
    return "";
  }
};
let mobile = /^07([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
export const nameCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (!regValue.test(val)) {
    return msg2;
  } else if (!regValueMax.test(val)) {
    return msg2;
  } else {
    return "";
  }
};

export const otpCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (val.length !== 4) {
    return msg2;
  } else {
    return "";
  }
};

export const userTypeCheck = (val, msg) => {
  if (val === "") {
    return msg;
  } else {
    return "";
  }
};
